import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { db } from "../../../../firebase";
import { set, ref } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Modal,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Stack,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Formik } from "formik";
import * as yup from "yup";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  decodeClientToken,
  decodeTokenRefresh,
  deleteToken,
  getClientToken,
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import {
  addBooking,
  deleteBooking,
} from "../../../../features/booking/bookingSlice";
import { getAddressAndLatLng } from "../../../../api/expandUrl";
import SnackBar from "../../../../components/snackbar/SnackBar";

import "../booking.css";
import {
  boxModalStyle,
  circularProgressStyle,
  suggestionBackgroundColor,
} from "./BookingStyle";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  dateFormat,
  formatDateTime,
  timeFormat,
} from "../../../../helpers/date/date";
import { sendICSEmailNow } from "../../../../api/resources/ics/ics";
import { getListCountryUseCase } from "./usecases/country/GetListCountryUseCase";
import { getListTourByCountry } from "./data/repository/tourRepo";
import { GetFrequentClient } from "./usecases/GetFrecuentClient";
import { CreateBackupUseCase } from "./usecases/backup/CreateBackupUseCase";
import { GetCounterRider } from "./usecases/GetCounterRider";
import { BookingObserver } from "./domain/BookingObserver";
import { modalStyle } from "./style/Booking.style";
import loadingStyle from "./style/loading.module.css";
import {
  getDriverStore,
  removeDriverStore,
  saveDriverStore,
} from "../../../../helpers/store/DriverStore";
import { CounterOfferObserver } from "./domain/CounterOfferObserver";
import { UpdateCounterOffer } from "./domain/UpdateCounterOffer";
import { SendNotificationDriver } from "./domain/SendNotificationDriver";
import { DeleteAllCounterOffers } from "./domain/DeleteAllCounterOffers";
import { DeleteBooking } from "./domain/DeleteBooking";
import { SendNotificationsDriver } from "./domain/SendNotificationsDriver";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const BookingNow = ({ model }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookingState = useSelector((state) => state.bookings);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);

  const [addressDestination, setAddressDestination] = useState(
    bookingState?.data ? bookingState?.data.destination.name : ""
  );
  const [coordDestination, setCoordDestination] = useState({
    lat: bookingState?.data ? bookingState?.data.destination.lat : null,
    lng: bookingState?.data ? bookingState?.data.destination.lng : null,
  });

  const [serviceList, setServiceList] = useState(
    bookingState?.data ? bookingState?.data.serviceList : []
  );
  const [route1, setRoute1] = useState("");
  const [indice, setIndice] = useState(-1);

  const checkoutSchema = yup.object().shape({
    persons: yup.number().required("required"),
    booking: yup.number().required("required"),
    vehicle: yup.number().required("required"),
    note: yup.string(),
  });

  const initialValues = bookingState?.data
    ? bookingState?.data
    : {
        persons: 1,
        vehicle: "",
        booking: 1,
        note: "",
      };

  const handSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddressOrigin(value);
    setCoordOrigin(ll);
  };

  const handSelectRoute = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    const list = [...serviceList];
    list[indice].service = value;
    list[indice].lat = ll.lat;
    list[indice].lng = ll.lng;
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    if (indice === -1) {
      setServiceList([...serviceList, { service: "" }]);
      setIndice(indice + 1);
    } else if (serviceList[indice].service !== "") {
      setServiceList([...serviceList, { service: "" }]);
      setIndice(indice + 1);
      setRoute1("");
    } else {
      window.alert("INGRESE UNA RUTA ANTES DE CREAR OTRO CAMPO");
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
    setIndice(indice - 1);
  };

  const handleSelectDestination = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddressDestination(value);
    setCoordDestination(ll);
  };

  const [isOrigin, setIsOrigin] = useState(false);

  const [openIntermediate, setOpenIntermediate] = useState(false);
  const [isIntermediate, setIsIntermediate] = useState(false);
  const [intermediateURL, setIntermediateURL] = useState("");

  const [url, setUrl] = useState("");
  const [addressOrigin, setAddressOrigin] = useState(
    bookingState?.data ? bookingState?.data.origin.name : ""
  );
  const [coordOrigin, setCoordOrigin] = useState({
    lat: bookingState?.data ? bookingState?.data.origin.lat : null,
    lng: bookingState?.data ? bookingState?.data.origin.lng : null,
  });
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [stateLocation, setStateLocation] = useState(true);
  const handleOpenLocationModal = (state) => {
    setOpenLocationModal(true);
    setStateLocation(state);
  };
  const handleCloseLocationModal = () => setOpenLocationModal(false);

  const handleOpenIntermediate = () => setOpenIntermediate(true);
  const handleCloseIntermediate = () => setOpenIntermediate(false);

  const handleIntermediateRoute = async () => {
    if (intermediateURL) {
      setIsIntermediate(true);
      try {
        const getIntermediateRoute = await getAddressAndLatLng(intermediateURL);

        if (getIntermediateRoute?.data?.message === "success")
          setIsIntermediate(false);

        const list = [...serviceList];
        list[indice].service = getIntermediateRoute?.data?.address;
        list[indice].lat = getIntermediateRoute?.data?.lat;
        list[indice].lng = getIntermediateRoute?.data?.lng;
        setServiceList(list);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleLocation = async () => {
    try {
      const getAddress = await getAddressAndLatLng(url);
      if (stateLocation) {
        setAddressOrigin(getAddress?.data?.address);
        setCoordOrigin({
          lat: getAddress?.data?.lat,
          lng: getAddress?.data?.lng,
        });
      } else {
        setAddressDestination(getAddress?.data?.address);
        setCoordDestination({
          lat: getAddress?.data?.lat,
          lng: getAddress?.data?.lng,
        });
      }
      handleCloseLocationModal();
    } catch (error) {
      handleCloseLocationModal();
      console.log(error);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleFormSubmit = async (values) => {
    if (coordOrigin.lat && coordOrigin.lng) {
      if (coordDestination.lat && coordDestination.lng) {
        const verify = verifyExpireTokenRefresh();
        if (!verify) {
          if (values.booking !== 1 && date == null && time == null) {
            snackBarDialog("SELECCIONE LA FECHA Y HORA", "error");
            return;
          }
          if (values.booking === 4 && country == null && tour == null) {
            snackBarDialog("SELECCIONE EL PAIS Y EL TOUR", "error");
            return;
          }

          if (values.booking !== 1) {
            const today = new Date();
            const { day, month, year, hour, minute } = formatDateTime(
              date.$d,
              time.$d
            );
            const dateOrder = new Date(year, month, day, hour, minute, 0);
            const diffInMillisec = dateOrder.getTime() - today.getTime();
            const diffInMin = diffInMillisec / (1000 * 60);
            if (values.booking != 1 && diffInMin <= 30) {
              snackBarDialog(
                "TODA RESERVA DEBE SER MAYOR A 30 MINUTOS",
                "error"
              );
              return;
            }
          }

          setIsLoading(true);
          const uuid = uuidv4();
          const nDate = new Date(Date.now());

          const data = createDataMySql(values, uuid, nDate);
          const backup = await CreateBackupUseCase(data);

          if (backup.message === "success") {
            const orderNumber = backup.insertId;
            const fData = createDataFirebase(values, uuid, orderNumber, nDate);

            await createBookingFirebase(fData);
            if (values.booking == 1) await SendNotificationsDriver();
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          deleteToken("ctknre");
          deleteToken("cdttkn");
          const origin = {
            name: addressOrigin,
            lat: coordOrigin.lat,
            lng: coordOrigin.lng,
          };
          const destination = {
            name: addressDestination,
            lat: coordDestination.lat,
            lng: coordDestination.lng,
          };
          dispatch(
            addBooking({
              ...values,
              id: uuidv4(),
              origin,
              destination,
              serviceList,
            })
          );
          navigate("/login");
        }
      } else {
        snackBarDialog("Ingrese el Destino del servicio", "error");
      }
    } else {
      snackBarDialog("Ingrese el Origen del servicio", "error");
    }
  };

  const [frequent, setFrequent] = useState(false);
  useEffect(() => {
    const getFrequentClient = async () => {
      try {
        const token = getTokenRefresh();
        const uid = decodeTokenRefresh(token);
        const response = await GetFrequentClient(uid);
        const counterResp = await GetCounterRider(uid);

        console.log(counterResp);

        if (response.message == "success") {
          setFrequent(response.frequent);
        } else {
          console.log("error");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFrequentClient();
  }, []);

  const createDataFirebase = (values, uuid, counter, nDate) => {
    const token = getTokenRefresh();
    const uid = decodeTokenRefresh(token);
    const clientToken = getClientToken();
    const { name, lastname, phone, email } = decodeClientToken(clientToken);

    return {
      uuid,
      client: uid,
      clientName: `${name} ${lastname}`,
      clientEmail: email,
      clientPhone: phone,
      origin: {
        name: addressOrigin,
        lat: coordOrigin.lat,
        lng: coordOrigin.lng,
      },
      destination: {
        name: addressDestination,
        lat: coordDestination.lat,
        lng: coordDestination.lng,
      },
      intermediate: serviceList,
      persons: values.persons,
      vehicle: values.vehicle,
      date: values.booking === 1 ? dateFormat(nDate) : dateFormat(date.$d),
      time: values.booking === 1 ? timeFormat(nDate) : timeFormat(time.$d),
      dateEnd: values.booking === 3 ? dateFormat(dateEnd.$d) : "",
      timeEnd: values.booking === 3 ? timeFormat(timeEnd.$d) : "",
      tour_id: values.booking === 4 ? tour.tour_id : "",
      tour_description: values.booking === 4 ? tour.description : "",
      note: values.note ? values.note : "",
      noteAdmin: "",
      currency: 1,
      payment_type: 1,
      payment_voucher: 1,
      tax: values.tax ? values.tax : 0,
      toll: 0,
      price: values.price ? values.price : 0,
      total: values.total ? values.total : 0,
      discount: 0,
      advance: 0,
      statusService: "PENDIENTE", //estado asignacion de conductor
      statusPayment: "PENDIENTE", //estado de pago del servicio servicio
      statusBooking: "PENDIENTE", //estado de pago para el conductor
      createBooking: "BOOKING",
      booking_type: values.booking === 5 ? 2 : values.booking,
      counterBooking: counter,
      created: Date.now(),
      isCreated: false,
      cotizado: frequent && values.booking != 1 ? true : false,
      phoneActive: true,
    };
  };

  const createDataMySql = (values, uuid, nDate) => {
    const token = getTokenRefresh();
    const uid = decodeTokenRefresh(token);
    return {
      uuid,
      userType: 1,
      originName: addressOrigin,
      originLat: coordOrigin.lat,
      originLng: coordOrigin.lng,
      originUrl: "",
      destinationName: addressDestination,
      destinationLat: coordDestination.lat,
      destinationLng: coordDestination.lng,
      destinationUrl: "",
      date: values.booking === 1 ? dateFormat(nDate) : dateFormat(date.$d),
      time: values.booking === 1 ? timeFormat(nDate) : timeFormat(time.$d),
      dateEnd: values.booking === 3 ? dateFormat(dateEnd.$d) : "",
      timeEnd: values.booking === 3 ? timeFormat(timeEnd.$d) : "",
      persons: values.persons,
      vehicleModel: values.vehicle,
      notes: values.note ? values.note.toUpperCase() : "",
      notesAdmin: "",
      userId: uid,
      driverId: 0,
      vehicleId: 0,
      paymentId: 0,
      bookingType: values.booking === 5 ? 2 : values.booking,
      tourId: values.booking === 4 ? tour.tour_id : 0,
      invoiceId: 0,
      createdBy: "BOOKING",
      status: 1,
      statePayment: "PENDIENTE",
      assigned: false,
      invoice: false,
      invoiceStatus: false,
      travelStatus: 1,
      currency: 1,
      paymentType: 1,
      paymentVoucher: 1,
      tax: values.tax ? values.tax : 0,
      toll: 0,
      price: values.price ? values.price : 0,
      total: values.total ? values.total : 0,
      discount: 0,
      advance: 0,
      quote: frequent && values.booking != 1 ? true : false,
    };
  };

  const createBookingFirebase = async (data) => {
    const dbRef = `bookingNow/${data.uuid}`;
    set(ref(db, dbRef), data)
      .then(async () => {
        if (data.booking_type === 1) {
          setOpenOffers(true);
          const dataEmail = {
            name: data.clientName,
            pedido: data.counterBooking,
            date: data.date,
            time: data.time,
            origen: data.origin.name,
            destino: data.destination.name,
            email: data.clientEmail,
            telefono: data.clientPhone,
            persons: data.persons,
            vehicle: data.vehicle,
            notas: data.note,
            web: "Booking",
          };
          await sendICSEmailNow("token", dataEmail);
        }

        if (bookingState?.data) {
          dispatch(deleteBooking(bookingState?.data));
        }
        setAddressOrigin("");
        setCoordOrigin({ lat: null, lng: null });
        setAddressDestination("");
        setCoordDestination({ lat: null, lng: null });
        setServiceList([]);
        snackBarDialog("PEDIDO CREADO, ver Historial");
      })
      .catch((error) => {
        console.log(error);
        snackBarDialog("ERROR: No se creo el Pedido", "error");
      });
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  // TOUR
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountrys = async () => {
      try {
        const response = await getListCountryUseCase();
        setCountries([]);
        console.log(response);
        setCountries(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCountrys();
  }, []);

  //SELECCIONA UN PAIS TRAER LISTA DE TOUR
  const [tour, setTour] = useState(null);
  const [tours, setTours] = useState([]);

  useEffect(() => {
    if (country) {
      const getListTour = async () => {
        try {
          console.log(country.id);
          const response = await getListTourByCountry(country.id);
          console.log(response);
          setTours([]);
          setTour(null);
          setTours(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      getListTour();
    }
  }, [country]);

  //OBSERVERS DE BOOKING
  const [bookingNow, setBookingNow] = useState(null);
  const [uidBooking, setUidBooking] = useState("");
  const [isBookingActive, setIsBookingActive] = useState(false);
  const [driverSelect, setDriverSelect] = useState(null);

  const [openOffers, setOpenOffers] = useState(false);
  const [offersList, setOfferList] = useState([]);
  const handleCloseOffer = () => setOpenOffers(false);

  useEffect(() => {
    const readBooking = async () => {
      const token = getTokenRefresh();
      if (token) {
        const uid = decodeTokenRefresh(token);
        const unsubscribe = BookingObserver(uid, (offerList) => {
          if (offerList.length > 0) {
            console.log("list", offerList);
            setBookingNow(offerList[0]);
            setIsBookingActive(true);
            setUidBooking(offerList[0].uuid);
            const driver = getDriverStore();
            if (driver != null) {
              setDriverSelect(driver);
            }
          } else {
            handleCloseOffer();
            setIsBookingActive(false);
            removeDriverStore();
          }
        });

        return () => {
          if (unsubscribe) unsubscribe();
        };
      }
    };
    readBooking();
  }, []);

  useEffect(() => {
    if (uidBooking != "") {
      const token = getTokenRefresh();
      const uid = decodeTokenRefresh(token);
      const unsubscribe = CounterOfferObserver(uid, uidBooking, (offers) => {
        if (offers.length > 0) setOfferList(offers);
        else setOfferList([]);
      });
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [uidBooking]);

  const acceptCounterOffer = async (offer) => {
    const idBooking = offer.idBooking;
    const idDriver = offer.idDriver;
    const idRider = offer.idRider;
    const price = offer.priceOffer;
    const driver = {
      driverName: offer.nameDriver,
      driverPhone: offer.phoneDriver,
      vehicleId: offer.vehicleId,
      brand: offer.brand,
      model: offer.model,
      bodywork: offer.bodywork,
      plate: offer.plate,
      color: offer.color,
      time: offer.timeArrived,
    };
    saveDriverStore(driver);
    setDriverSelect(driver);
    await UpdateCounterOffer(idBooking, idDriver, price);
    await SendNotificationDriver(idDriver);
    await DeleteAllCounterOffers(idRider, idBooking);
  };

  const cancelBooking = (idBooking) => {
    DeleteBooking(idBooking);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              onSubmit={handleSubmit}
              sx={{ mt: 1, margin: "0px 0px 3px 0px" }}
            >
              <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={6}>
                  <PlacesAutocomplete
                    value={addressOrigin}
                    onChange={setAddressOrigin}
                    onSelect={handSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div key={suggestions.description}>
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            onChange={handleChange("weight")}
                            endAdornment={
                              addressOrigin ? (
                                <InputAdornment
                                  position="end"
                                  onClick={handleServiceAdd}
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    <AddOutlinedIcon />
                                  </IconButton>
                                </InputAdornment>
                              ) : (
                                <InputAdornment
                                  position="end"
                                  onClick={(e) => handleOpenLocationModal(true)}
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    <LinkOutlinedIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                            aria-describedby="outlined-weight-helper-text"
                            {...getInputProps({
                              placeholder: t("origin"),
                              className: "location-search-input",
                            })}
                          />
                        </FormControl>
                        <Box
                          key={suggestions.description}
                          className="autocomplete-dropdown-container suggestions"
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? suggestionBackgroundColor("#fafafa")
                              : suggestionBackgroundColor("#ffffff");
                            return (
                              <div
                                key={suggestion.description}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </Box>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Grid>
                <Modal
                  open={openLocationModal}
                  onClose={handleCloseLocationModal}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                >
                  <Box sx={boxModalStyle}>
                    <Typography id="modal-title" variant="h5" component="h2">
                      URL GoogleMaps(https://goo.gl/maps/...)
                    </Typography>
                    <TextField
                      fullWidth
                      id="modal-description"
                      type="text"
                      name="originURL"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      sx={{ mt: 1 }}
                    />
                    <Button color="primary" onClick={handleLocation}>
                      aceptar
                    </Button>
                    {isOrigin && (
                      <CircularProgress size={24} sx={circularProgressStyle} />
                    )}
                    <Button color="warning" onClick={handleCloseLocationModal}>
                      Cancelar
                    </Button>
                  </Box>
                </Modal>

                {serviceList.map((singleService, index) => (
                  <Grid key={index} item xs={12} sm={6}>
                    <PlacesAutocomplete
                      name="service"
                      value={
                        serviceList[index].service
                          ? serviceList[index].service
                          : route1
                      }
                      onChange={setRoute1}
                      onSelect={handSelectRoute}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div key={suggestions.description}>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              onChange={handleChange("weight")}
                              endAdornment={
                                serviceList[index].service ? (
                                  <InputAdornment
                                    position="end"
                                    onClick={() => handleServiceRemove(index)}
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      <ClearOutlinedIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ) : (
                                  <InputAdornment
                                    position="end"
                                    onClick={handleOpenIntermediate}
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      <LinkOutlinedIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }
                              aria-describedby="outlined-weight-helper-text"
                              {...getInputProps({
                                placeholder: `Route ${index + 1}`,
                                className: "location-search-input",
                              })}
                            />
                          </FormControl>
                          <Box
                            key={suggestions.description}
                            className="autocomplete-dropdown-container suggestionsDestination"
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? suggestionBackgroundColor("#fafafa")
                                : suggestionBackgroundColor("#ffffff");
                              return (
                                <div
                                  key={suggestion.description}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </Box>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                ))}
                <Modal
                  open={openIntermediate}
                  onClose={handleCloseIntermediate}
                  aria-labelledby="modal-inter-title"
                  aria-describedby="modal-inter-description"
                >
                  <Box sx={boxModalStyle}>
                    <Typography id="modal-title" variant="h5" component="h2">
                      URL GoogleMaps(https://goo.gl/maps/...)
                    </Typography>
                    <TextField
                      fullWidth
                      id="modal-description"
                      type="text"
                      name="originURL"
                      value={intermediateURL}
                      onChange={(e) => {
                        setIntermediateURL(e.target.value);
                      }}
                      sx={{ mt: 1 }}
                    />
                    <Button color="primary" onClick={handleIntermediateRoute}>
                      aceptar
                    </Button>
                    {isOrigin && (
                      <CircularProgress size={24} sx={circularProgressStyle} />
                    )}
                    <Button color="warning" onClick={handleCloseIntermediate}>
                      Cancelar
                    </Button>
                  </Box>
                </Modal>

                <Grid item xs={12} sm={6}>
                  <PlacesAutocomplete
                    value={addressDestination}
                    onChange={setAddressDestination}
                    onSelect={handleSelectDestination}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div key={suggestions.description}>
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            onChange={handleChange("weight")}
                            endAdornment={
                              addressDestination ? (
                                ""
                              ) : (
                                <InputAdornment
                                  position="end"
                                  onClick={(e) =>
                                    handleOpenLocationModal(false)
                                  }
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    <LinkOutlinedIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }
                            aria-describedby="outlined-weight-helper-text"
                            {...getInputProps({
                              placeholder: t("destination"),
                              className: "location-search-input",
                            })}
                          />
                        </FormControl>
                        <Box
                          key={suggestions.description}
                          className="autocomplete-dropdown-container suggestionsDestination"
                        >
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            const style = suggestion.active
                              ? suggestionBackgroundColor("#fafafa")
                              : suggestionBackgroundColor("#ffffff");
                            return (
                              <div
                                key={suggestion.description}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </Box>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.persons}
                    fullWidth
                    type="number"
                    label={t("persons")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="persons"
                    error={!!touched.persons && !!errors.persons}
                    helperText={touched.persons && errors.persons}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>{t("vehicle")}</InputLabel>
                    <Select
                      value={values.vehicle}
                      id="vehicle"
                      name="vehicle"
                      label={t("vehicle")}
                      onChange={handleChange}
                      error={!!touched.vehicle && !!errors.vehicle}
                    >
                      {model.map((model, index) => (
                        <MenuItem key={index} value={model.id}>
                          {t(model.name)}
                        </MenuItem>
                      ))}
                    </Select>
                    {!!touched.vehicle && !!errors.vehicle ? (
                      <FormHelperText> Seleccione un Vehiculo </FormHelperText>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={values.booking === 3 ? 4 : 6}
                  md={values.booking === 3 ? 4 : 6}
                >
                  <FormControl fullWidth>
                    <InputLabel>{t("SOLICITAR")}</InputLabel>
                    <Select
                      value={values.booking}
                      id="booking"
                      name="booking"
                      label={t("SOLICITAR")}
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>{t("now")}</MenuItem>
                      <MenuItem value={2}>{t("later")}(reservas)</MenuItem>
                      <MenuItem value={3}>{t("perHours")}</MenuItem>
                      <MenuItem value={4}>{t("Tour")}</MenuItem>
                      <MenuItem value={5}>{t("Cotizacion")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sm={values.booking === 3 ? 2 : 3}
                  sx={{
                    display: {
                      xs: values.booking != 1 ? "block" : "none",
                      md: values.booking != 1 ? "block" : "none",
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <MobileDatePicker
                        label={
                          values.booking === 3 ? t("startDate") : t("date")
                        }
                        inputFormat="DD/MM/YYYY"
                        name="date"
                        value={date}
                        onChange={(newValue) => {
                          setDate(newValue);
                          setDateEnd(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sm={values.booking === 3 ? 2 : 3}
                  sx={{
                    display: {
                      xs: values.booking != 1 ? "block" : "none",
                      md: values.booking != 1 ? "block" : "none",
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <MobileTimePicker
                        label={
                          values.booking === 3 ? t("startTime") : t("time")
                        }
                        value={time}
                        name="time"
                        onChange={(newValue) => {
                          setTime(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sm={2}
                  sx={{
                    display: {
                      xs: values.booking === 3 ? "block" : "none",
                      md: values.booking === 3 ? "block" : "none",
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={2}>
                      <MobileDatePicker
                        label={t("endDate")}
                        inputFormat="DD/MM/YYYY"
                        name="dateEnd"
                        value={dateEnd}
                        onChange={(newValue) => {
                          setDateEnd(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sm={2}
                  sx={{
                    display: {
                      xs: values.booking === 3 ? "block" : "none",
                      md: values.booking === 3 ? "block" : "none",
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <MobileTimePicker
                        label={t("endTime")}
                        value={timeEnd}
                        name="timeEnd"
                        onChange={(newValue) => {
                          setTimeEnd(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>

                {countries && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: {
                        xs: values.booking === 4 ? "block" : "none",
                        md: values.booking === 4 ? "block" : "none",
                      },
                    }}
                  >
                    <Autocomplete
                      value={country}
                      disablePortal
                      id="select-origin"
                      onChange={(e, newValue) => {
                        setCountry(newValue);
                      }}
                      options={countries}
                      getOptionLabel={(option) => `${option.name}`}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField {...params} label={t("PAIS")} />
                      )}
                    />
                  </Grid>
                )}

                {tours.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      display: {
                        xs: values.booking === 4 ? "block" : "none",
                        md: values.booking === 4 ? "block" : "none",
                      },
                    }}
                  >
                    <Grid container sx={{ alignItems: "center" }}>
                      <Grid item xs={11}>
                        <Autocomplete
                          value={tour}
                          disablePortal
                          id="select-origin"
                          onChange={(e, newValue) => {
                            setTour(newValue);
                          }}
                          options={tours}
                          getOptionLabel={(option) => `${option.description}`}
                          isOptionEqualToValue={(option, value) =>
                            option.tour_id === value.tour_id
                          }
                          renderInput={(params) => (
                            <TextField {...params} label={t("TOUR")} />
                          )}
                        />
                      </Grid>
                      {tour && (
                        <Grid item xs={1} style={{ height: "55px" }}>
                          <InfoOutlinedIcon
                            sx={{
                              backgroundColor: "primary.main",
                              color: "white",
                              borderRadius: "5px",
                              padding: "3px",
                              cursor: "pointer",
                              height: "100%",
                              width: "100%",
                            }}
                            onClick={() => window.open(`${tour.url}`, "_blank")}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <TextField
                    value={values.note}
                    fullWidth
                    type="text"
                    label={t("notes")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="note"
                    error={!!touched.note && !!errors.note}
                    helperText={touched.note && errors.note}
                  />
                </Grid>

                <Grid item xs={isBookingActive ? 11 : 12}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{ height: 45 }}
                    disabled={isLoading}
                  >
                    <Typography variant="string">
                      {values.booking === 1 ? t("book_now") : t("book_later")}
                    </Typography>
                  </Button>
                </Grid>

                {isBookingActive && (
                  <Grid item xs={1}>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{
                        height: 45,
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 0,
                      }}
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenOffers(true);
                      }}
                    >
                      <p
                        className={loadingStyle.loader}
                        style={{ height: 30, width: 30, margin: "auto" }}
                      />

                      <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {offersList.length}
                      </p>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </form>
        )}
      </Formik>

      <Modal open={openOffers} onClose={handleCloseOffer}>
        <Box
          style={{
            ...modalStyle,
            background: "white",
            overflowY: "auto",
          }}
          sx={{
            width: {
              xs: "95%",
              sm: "500px",
            },
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontSize: 18,
              fontWeight: "bold",
              padding: 3,
            }}
          >
            MI PEDIDO
          </p>
          <div
            style={{
              background: `${bookingNow?.idDriver ? "#bfeaaa" : "#d8dcd6"}`,
              border: "2px solid black",
              borderRadius: "5px",
              width: "95%",
              margin: "auto",
              padding: 2,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <p style={{ textAlign: "center" }}>
                  <span style={{ fontWeight: "bold" }}>PEDIDO:</span>{" "}
                  {bookingNow?.counterBooking}{" "}
                </p>
              </Grid>

              <Grid item xs={12} sm={12}>
                {bookingNow?.idDriver && (
                  <div>
                    <span style={{ fontWeight: "bold" }}>PEDIDO ACEPTADO</span>
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                {bookingNow?.idDriver && (
                  <div>
                    <span style={{ fontWeight: "bold" }}>
                      CONDUCTOR LLEGARA EN:
                    </span>
                    {driverSelect?.time}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                {bookingNow?.idDriver ? (
                  <div>
                    <span style={{ fontWeight: "bold" }}>CONDUCTOR:</span>
                    {driverSelect?.driverName}
                  </div>
                ) : (
                  <>CONDUCTOR: ...</>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                {bookingNow?.idDriver ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "15px",
                    }}
                  >
                    <p>
                      <span style={{ fontWeight: "bold" }}>PLACA:</span>
                      {driverSelect?.plate}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>MARCA:</span>
                      {driverSelect?.bodywork}
                    </p>
                    <p>
                      <span style={{ fontWeight: "bold" }}>COLOR:</span>
                      {driverSelect?.color}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <p>
                  <span style={{ fontWeight: "bold" }}>TEL:</span>{" "}
                  {driverSelect?.driverPhone}
                </p>
              </Grid>
              <Grid item xs={6} sm={6}>
                <p>
                  <span style={{ fontWeight: "bold" }}>FECHA:</span>{" "}
                  {bookingNow?.date}
                </p>
              </Grid>
              <Grid item xs={6} sm={6}>
                <p>
                  <span style={{ fontWeight: "bold" }}>HORA:</span>{" "}
                  {bookingNow?.time}
                </p>
              </Grid>
              {bookingNow?.idDriver ? (
                <></>
              ) : (
                <Grid item xs={12} sm={12} textAlign={"end"} padding={1}>
                  <button
                    style={{
                      border: "none",
                      borderRadius: "5px",
                      background: "red",
                      padding: "5px",
                      color: "white",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      cancelBooking(bookingNow.uuid);
                    }}
                  >
                    CANCELAR
                  </button>
                </Grid>
              )}
            </Grid>
          </div>

          <Typography
            style={{
              textAlign: "center",
              fontSize: 18,
              fontWeight: "bold",
              padding: 3,
            }}
          >
            BUSCANDO CONDUCTORES CERCANOS
          </Typography>
          <p
            style={{
              position: "absolute",
              top: 5,
              right: 3,
              background: "red",
              borderRadius: "10px",
              margin: "0px 5px 0px 0px",
              textAlign: "center",
              alignItems: "center",
              color: "white",
              fontWeight: "bold",
              fontSize: "14px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
            onClick={handleCloseOffer}
          >
            X
          </p>
          <Grid container spacing={1}>
            {offersList.length > 0 ? (
              offersList.map((offer, idx) => {
                return (
                  <Grid key={idx} item xs={12}>
                    <div
                      style={{
                        background: "#d8dcd6",
                        border: "2px solid black",
                        borderRadius: "5px",
                        width: "90%",
                        margin: "auto",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "4px",
                        }}
                      >
                        <p>
                          <span style={{ fontWeight: "bold" }}>PRECIO:</span>{" "}
                          {offer.priceOffer}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>TIEMPO:</span>{" "}
                          {offer.timeArrived}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          padding: "4px",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>CONDUCTOR:</span>
                        {offer.nameDriver}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "4px",
                          fontSize: "14px",
                        }}
                      >
                        <p>
                          <span style={{ fontWeight: "bold" }}>PLACA:</span>
                          {offer.plate}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>MARCA:</span>
                          {offer.bodywork}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>COLOR:</span>
                          {offer.color}
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <button
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            background: "green",
                            padding: "5px",
                            color: "white",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            acceptCounterOffer(offer);
                          }}
                        >
                          ACEPTAR
                        </button>
                      </div>
                    </div>
                  </Grid>
                );
              })
            ) : (
              <div style={{ width: "100%" }}>
                <p
                  className={loadingStyle.loader}
                  style={{
                    height: 50,
                    width: 50,
                    background: "red",
                    margin: "auto",
                  }}
                />
                <p style={{ textAlign: "center", margin: "auto" }}>
                  ELIGE UNO DE LOS CONDUCTORES
                </p>
              </div>
            )}
          </Grid>
          <Button
            fullWidth
            size="small"
            color="info"
            variant="contained"
            sx={{ fontWeight: "bold", background: "#128C7E" }}
            href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${bookingNow?.counterBooking},%0A *Cliente:* ${bookingNow?.clientName},%0A *Origen:* ${bookingNow?.origin?.name}, *Destino:* ${bookingNow?.destination?.name} `}
            target={"_blank"}
          >
            Consultar al 958200900 &nbsp;
            <WhatsAppIcon />
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default BookingNow;
