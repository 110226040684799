const IMAGE_PROFILE = "image_profile";

export const setImageProfile = (url) => {
  window.localStorage.setItem(IMAGE_PROFILE, JSON.stringify(url));
};

export const getImageProfile = () => {
  const imageStore = window.localStorage.getItem(IMAGE_PROFILE);
  if (imageStore != undefined && imageStore != null) {
    const url = JSON.parse(imageStore);
    return url;
  }
  return null;
};

export const deleteImageProfile = () => {
  window.localStorage.removeItem(IMAGE_PROFILE);
};
