import { baseBackend } from "../../../../../../api/base/baseUrl";
import { URL_COUNTRY } from "./endpoints";

export const getListCountry = async () => {
  return await baseBackend.get(URL_COUNTRY, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
