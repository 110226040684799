import { baseBackend } from "../../../../../../api/base/baseUrl";
import { URL_TOUR } from "./endpoints";

export const getListTour = async (token) => {
  return await baseBackend.get(URL_TOUR, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getListTourByCountry = async (country_id) => {
  return await baseBackend.get(`${URL_TOUR}/country/${country_id}`, {
    headers: {
      "Content-Type": "applicaciont/json",
    },
  });
};

export const createTour = async (tour) => {
  return await baseBackend.post(URL_TOUR, JSON.stringify({ tour }), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateTour = async (tour) => {
  return await baseBackend.put(URL_TOUR, JSON.stringify({ tour }), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteTour = async (id) => {
  return await baseBackend.delete(`${URL_TOUR}/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// export const updateBookingBusiness = async (token, booking) => {
//   return await baseBackend.put(
//     URL_BOOKING_BUSINESS,
//     JSON.stringify({ booking }),
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };
