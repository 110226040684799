import { URL_TRAVELLER } from "../helpers/endpoint";
import { baseBackend } from "./base/baseUrl";

export const getTravellers = async (token, id) => {
  return await baseBackend.get(`${URL_TRAVELLER}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createTraveller = async (token, name, age, document, country) => {
  return await baseBackend.post(
    URL_TRAVELLER,
    JSON.stringify({
      name,
      age,
      document,
      country,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateTraveller = async (token, traveller) => {
  return await baseBackend.put(URL_TRAVELLER, JSON.stringify({ traveller }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteTraveller = async (token, id) => {
  return await baseBackend.delete(`${URL_TRAVELLER}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
