import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import TopMenu from "../../../components/topbar/TopMenu";
import Footer from "../../../components/footer/Footer";
import ListQuote from "./history/ListQuote";
import HistoryBookingNow from "./history/HistoryBookingNow";
import {
  decodeTokenRefresh,
  getTokenRefresh,
} from "../../../helpers/token/refreshToken";
import { getClientId } from "../../../api/client";
import HistoryBookingLater from "./history/HistoryBookingLater";
import HistoryTotal from "./history/HistoryTotal";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const HistoryTab = () => {
  const [value, setValue] = useState(0);
  const [client, setClient] = useState("");
  const theme = createTheme();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => setValue(newValue);
  //?id=trpus9rewdmpkuk9ix6c
  useEffect(() => {
    const getClient = async () => {
      try {
        const token = getTokenRefresh();
        const id = decodeTokenRefresh(token);
        const response = await getClientId(token, id);
        setClient(response?.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getClient();
  }, []);

  return (
    <>
      <TopMenu />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Booking-Tabs"
                centered
                textColor="primary"
                indicatorColor="primary"
                sx={{ "& .MuiTabs-flexContainer": { flexWrap: "wrap" } }}
              >
                <Tab
                  label={t("now")}
                  {...a11yProps(0)}
                  wrapped
                  sx={{ color: "#000000", fontSize: 15, fontWeight: "bold" }}
                />
                <Tab
                  label={t("quotation")}
                  {...a11yProps(1)}
                  sx={{ color: "#000000", fontSize: 15, fontWeight: "bold" }}
                />
                <Tab
                  label={t("My Bookings")}
                  {...a11yProps(2)}
                  sx={{ color: "#000000", fontSize: 15, fontWeight: "bold" }}
                />
                <Tab
                  label={t("history")}
                  {...a11yProps(3)}
                  sx={{ color: "#000000", fontSize: 15, fontWeight: "bold" }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <HistoryBookingNow client={client} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ListQuote client={client} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <HistoryBookingLater />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <HistoryTotal />
            </TabPanel>
          </Box>
        </Container>
      </ThemeProvider>
      <Footer />
    </>
  );
};

export default HistoryTab;
