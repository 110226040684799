import { URL_CONFIRM_EMAIL } from "../helpers/endpoint";
import { baseBackend } from "./base/baseUrl";

export const verifyEmail = async (token) => {
  return await baseBackend.get(URL_CONFIRM_EMAIL, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
