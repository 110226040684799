import { URL_RESET } from "../helpers/endpoint";
import { baseBackend } from "./base/baseUrl";

export const getResetPassword = async (id, token) => {
  return await baseBackend.get(`${URL_RESET}/${id}/${token}`, {
    headers: { "Content-Type": "application/json" },
    //withCredentials: true,
  });
};

export const postResetPassword = async (id, token, password) => {
  return await baseBackend.post(
    `${URL_RESET}/${id}/${token}`,
    JSON.stringify({ password }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};
