import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Button,
  TextField,
  Container,
  CssBaseline,
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";

import { REGEX_PHONE } from "../../../helpers/regex";
import { getGenres } from "../../../api/genres";
import { getClientId } from "../../../api/client";
import { updateClient } from "../../../api/client";
import TopMenu from "../../../components/topbar/TopMenu";
import {
  decodeTokenRefresh,
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../helpers/token/refreshToken";

const Profile = () => {
  const theme = createTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [genre, setGenre] = useState([]);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    lastname: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    phone: yup
      .string()
      .matches(REGEX_PHONE, "Phone number is not valid")
      .required("required"),
    address: yup.string().required("required"),
  });

  useEffect(() => {
    try {
      const genres = async () => {
        const response = await getGenres();
        const data = response.data.genres.filter((genre) => genre.id);
        setGenre(data);
      };

      genres();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    try {
      const clientData = async () => {
        const verify = verifyExpireTokenRefresh();
        if (verify) navigate("/");

        const token = getTokenRefresh();
        const id = decodeTokenRefresh(token);
        try {
          const response = await getClientId(token, id);
          console.log(response?.data[0]);
          setClient(response?.data[0]);
        } catch (error) {
          console.log(error);
        }
      };

      clientData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleFormSubmit = async (values) => {
    //e.preventDefault();
    console.log(values);
    try {
      const token = getTokenRefresh("ctknre");

      const newClient = {
        id: values.id,
        name: values.name.toUpperCase(),
        lastname: values.lastname.toUpperCase(),
        phone: values.phone,
        address: values.address.toUpperCase(),
        genres_id: values.genres_id,
      };

      const response = await updateClient(token, newClient);
      console.log(response?.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <TopMenu />
      <div>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box p={1}>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography
                  variant="h4"
                  align="left"
                  sx={{ fontWeight: "bold" }}
                >
                 {t("my_profile")}
                </Typography>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate("/client/traveller");
                  }}
                  sx={{ height: "90%" }}
                >
                  {t("travellers")}
                </Button>
              </Box>

              {client ? (
                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={client}
                  validationSchema={checkoutSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              type="text"
                              label={t("name")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.name}
                              name="name"
                              error={!!touched.name && !!errors.name}
                              helperText={touched.name && errors.name}
                              sx={{ gridColumn: "span 2" }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              type="text"
                              label={t("lastname")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.lastname}
                              name="lastname"
                              error={!!touched.lastname && !!errors.lastname}
                              helperText={touched.lastname && errors.lastname}
                              sx={{ gridColumn: "span 2" }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              disabled
                              type="text"
                              label="Email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              name="email"
                              error={!!touched.email && !!errors.email}
                              helperText={touched.email && errors.email}
                              sx={{ gridColumn: "span 4" }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              type="text"
                              label={t("phone")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.phone}
                              name="phone"
                              error={!!touched.phone && !!errors.phone}
                              helperText={touched.phone && errors.phone}
                              sx={{ gridColumn: "span 4" }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>{t("genre")}</InputLabel>
                              <Select
                                id="genres_id"
                                value={values.genres_id}
                                name="genres_id"
                                label={t("genre")}
                                onChange={handleChange}
                              >
                                {genre.map((genre, index) => (
                                  <MenuItem key={index} value={genre.id}>
                                    {t(genre.name)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              type="text"
                              label={t("address")}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.address}
                              name="address"
                              error={!!touched.address && !!errors.address}
                              helperText={touched.address && errors.address}
                              sx={{ gridColumn: "span 4" }}
                            />
                          </Grid>
                          <Grid item minHeight={60} xs={12}>
                            <Button
                              fullWidth
                              type="submit"
                              color="primary"
                              variant="contained"
                              sx={{ height: "100%" }}
                            >
                              {t("updateAccount")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  )}
                </Formik>
              ) : (
                <span>loading...</span>
              )}
            </Box>
          </Container>
        </ThemeProvider>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
