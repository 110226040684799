import { baseBackend } from "../base/baseUrl";

export const generateOpenPayURL = async (data) => {
  const currency = data.currency;

  const pkToken =
    currency == "PEN"
      ? process.env.REACT_APP_OPENPAY_PK_PEN
      : process.env.REACT_APP_OPENPAY_PK_USD;

  const openpay = {
    data: data,
    token: pkToken,
  };

  return await baseBackend.post(
    "/api/v1/openpay-payment",
    JSON.stringify({ openpay }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const checkStatusPayment = async (transactionId, currency) => {
  const pkToken =
    currency === "PEN"
      ? process.env.REACT_APP_OPENPAY_PK_PEN
      : process.env.REACT_APP_OPENPAY_PK_USD;

  const data = {
    transactionId,
    token: pkToken,
  };

  return await baseBackend.post(
    "/api/v1/openpay-status",
    JSON.stringify({ data }),
    { headers: { "Content-Type": "application/json" } }
  );
};
