import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import TopMenu from "../../components/topbar/TopMenu";

import BookingTab from "../users/clients/BookingTab";
import {
  decodeClientToken,
  decodeTokenRefresh,
  getToken,
  setToken,
} from "../../helpers/token/refreshToken";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { countries } from "../../helpers/countrys";
import { updateRegisterUseCase } from "./login/domain/updateRegisterUseCase";
import SnackBar from "../../components/snackbar/SnackBar";
import { validateEmail } from "../../helpers/validate/validateEmail";

const Home = () => {
  const [statusEmail, setStatusEmail] = useState(false);
  const [UID, setUID] = useState("");
  const [emailUser, setEmail] = useState("");
  const [country, setCountry] = useState(countries[172].label);
  const [codeCountry, setCodeCountry] = useState(countries[172].phone);
  const [phoneUser, setPhone] = useState("");

  const [completeRegister, setCompleteRegister] = useState(false);
  const openCompleteRegister = () => setCompleteRegister(true);

  const closeCompleteRegister = (event, reason) => {
    if (reason && reason == "backdropClick") {
      return;
    }
    setCompleteRegister(false);
  };

  const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  useEffect(() => {
    const clientToken = getToken("ctknre");
    const dataToken = getToken("cdttkn");

    if (clientToken != null && dataToken != null) {
      const uid = decodeTokenRefresh(clientToken);
      const { email, phone } = decodeClientToken(dataToken);
      setUID(uid);
      setEmail(email);

      if (email === "" || phone === "") {
        openCompleteRegister();
        setStatusEmail(email === "");
      }
    } else {
      console.log("NO LOGIN");
    }
  }, []);

  const handleCompleteRegister = async () => {
    try {
      const isValidEmail = validateEmail(emailUser);

      if (statusEmail && !isValidEmail) {
        snackBarDialog("FORMATO DE EMAIL NO VALIDO", "error");
        return;
      }

      if (phoneUser.length < 6) {
        snackBarDialog("NUMERO DE TELEFONO NO VALIDO", "error");
        return;
      }

      const rider = {
        id: UID,
        email: emailUser,
        phone: `${codeCountry} ${phoneUser.replace(/\s/g, "")}`,
        country: country,
      };
      const response = await updateRegisterUseCase(rider);

      setToken("cdttkn", response.data.dataToken);
      closeCompleteRegister();
      snackBarDialog("REGISTRO COMPLETADO");
    } catch (error) {
      snackBarDialog("HA OCURRIDO UN ERROR EN EL SERVIDOR", "error");
      console.log(error);
    }
  };

  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  // const handlePhoneChange = (e) => {
  //   let inputPhoneNumber = e.target.value;

  //   const part = inputPhoneNumber.split(" ");
  //   const code = part[0];
  //   let phoneNumber = part.slice(1).join(" ");
  //   // Eliminar espacios existentes y formatear el número
  //   phoneNumber = phoneNumber.replace(/\s/g, "");
  //   const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  //   setPhone(`${code} ${formattedPhoneNumber}`);
  // };

  const formatPhoneNumber = (input) => {
    const phoneNumber = input.replace(/\s+/g, "");
    let formattedPhoneNumber = "";

    for (let i = 0; i < phoneNumber.length; i++) {
      if (i > 0 && i % 3 === 0) formattedPhoneNumber += " ";

      formattedPhoneNumber += phoneNumber[i];
    }

    return formattedPhoneNumber;
  };

  return (
    <div>
      <TopMenu />
      <BookingTab />
      <Footer />

      <Modal
        open={completeRegister}
        onClose={closeCompleteRegister}
        disableEscapeKeyDown={true}
      >
        <Box minWidth={350} sx={modalStyles}>
          <Typography variant={"h3"} sx={{ color: "white" }}>
            COMPLETAR REGISTRO
          </Typography>
          <Grid container spacing={1} marginTop={"4px"}>
            {statusEmail && (
              <Grid item xs={12} sm={12}>
                <TextField
                  value={emailUser}
                  fullWidth
                  type="email"
                  label={"Email"}
                  onChange={(e) => setEmail(e.target.value)}
                  name="emailUser"
                  placeholder={"Email"}
                  InputLabelProps={{ style: { color: "white" } }}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="country-select"
                options={countries}
                defaultValue={countries[172]}
                autoHighlight
                onChange={(e, newValue) => {
                  if (newValue) {
                    const label = newValue.label; 
                    const code = newValue.phone;
                    setCodeCountry(code);
                    setCountry(label);
                  }
                }}
                getOptionLabel={(option) =>
                  `(${option.phone}) ${option.label}`
                }
                renderOption={(props, opt) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${opt.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${opt.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {opt.label} ({opt.code}) {opt.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                value={phoneUser}
                fullWidth
                type="tel"
                label="Telefono"
                onChange={(event) => {
                  const { value } = event.target;
                  const formattedPhoneNumber = formatPhoneNumber(value);
                  setPhone(formattedPhoneNumber);
                }}
                name="phoneUser"
                placeholder="Telefono"
                inputProps={{ maxLength: 18 }}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        {codeCountry}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#1976D2", height: "45px" }}
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  handleCompleteRegister();
                }}
              >
                FINALIZAR REGISTRO
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </div>
  );
};

export default Home;
