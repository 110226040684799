import { baseBackend } from "../../../../../../api/base/baseUrl";

const URL_RIDER = "api/v2/rider";
export const getFrequentRider = async (riderId) => {
  return await baseBackend.get(`${URL_RIDER}/frequent/id/${riderId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getCounterRider = async (riderId) => {
  return await baseBackend.get(`api/v1/counter-rider/${riderId}`, {
    headers: { "Content-Type": "application/json" },
  });
};
