import { loginWithGoogle } from "../repository/login.repository";

export const loginWithGoogleUseCase = async (token) => {
  try {
    const response = await loginWithGoogle(token);
    return response;
  } catch (error) {
    return null;
  }
};
