import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    width: "70%",
    marginHorizontal: "auto",
  },
  title: {
    fontFamily: "Helvetica-Bold",
  },
  text: {
    fontFamily: "Helvetica",
  },
});

const Body = ({ data }) => (
  <View style={styles.headerContainer}>
    <Text style={{ ...styles.title, marginTop: 10 }}>
      TM-{data.order_id.split("-")[0]}
    </Text>
    <Text style={styles.text}>
      <Text style={styles.title}>Cliente: </Text>
      {data.customer.name} {data.customer.last_name}
    </Text>
    <Text style={styles.text}>
      <Text style={styles.title}>Monto: </Text>
      {data.currency} {data.amount}
    </Text>
    <Text style={styles.text}>
      <Text style={styles.title}>Fecha: </Text>
      {data.operation_date}
    </Text>
    <Text style={styles.text}>
      <Text style={styles.title}>Descripcion: </Text>
      {data.description}
    </Text>
    <Text style={styles.text}>
      <Text style={styles.title}>Estado del Pago: </Text>
      {data.status}
    </Text>
  </View>
);

export default Body;
