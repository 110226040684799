import { get, ref } from "firebase/database";
import { db } from "../../../../../firebase";
import { sendPushNotificationByTokens } from "../../../../../api/fcm/PushNotification";

export const SendNotificationsDriver = async () => {
  try {
    const tokenPath = `Token/Driver`;
    const dbTkn = ref(db, tokenPath);
    const snapshot = await get(dbTkn);
    const resp = snapshot.val();
    if (resp != null) {
      const listFilter = Object.values(resp).filter(
        (token) => Boolean(token.isBooking) && Boolean(token.isPremium)
      );
      const tokenList = listFilter.map((token) => token.token);

      const title = "PEDIDOS PENDIENTES";
      const body = "UN CLIENTE HA SOLICITADO UN TAXI...";
      await sendPushNotificationByTokens(tokenList, title, body);
    }
  } catch (error) {
    console.log(error);
  }
};
