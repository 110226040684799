import React from "react";
import { Box, Button } from "@mui/material";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Header from "./elements/Header";
import Body from "./elements/Body";

const Voucher = ({ data }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  return (
    <PDFDownloadLink
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <Header />
            <Body data={data} />
            {/*
            <Contact invoice={tableData} />

            <TableRider invoice={tableData} /> */}
          </Page>
        </Document>
      }
      fileName={"voucher-taximundo.pdf"}
    >
      <Box width={"100%"}>
        <Button fullWidth variant="contained" color="success">
          <PictureAsPdfIcon /> DESCARGAR
        </Button>
      </Box>
    </PDFDownloadLink>
  );
};

export default Voucher;
