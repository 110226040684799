import { getCounterRider } from "../data/repository/rider.repository";


export const GetCounterRider = async (id) => {
  try {
    const response = await getCounterRider(id);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
