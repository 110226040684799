import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  createTheme,
  ThemeProvider,
  Container,
  Grid,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
  Paper,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Modal,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { Formik } from "formik";
import * as yup from "yup";
import {
  createTraveller,
  deleteTraveller,
  getTravellers,
  updateTraveller,
} from "../../../api/traveller";
import TopMenu from "../../../components/topbar/TopMenu";
import {
  decodeTokenRefresh,
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../../helpers/token/refreshToken";
import { getSunatDni } from "../../../api/sunat/sunat";
import SnackBar from "../../../components/snackbar/SnackBar";

const Traveller = () => {
  const theme = createTheme();
  const { t } = useTranslation();
  const [traveller, setTraveller] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");

  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("required"),
    age: yup.number().required("required"),
    country: yup.string().required("required"),
    dni: yup.string().required("required"),
  });

  const initialValues = {
    name: "",
    age: "",
    country: "",
    dni: "",
  };

  useEffect(() => {
    const travellers = async () => {
      const token = getTokenRefresh();
      const uid = decodeTokenRefresh(token);
      try {
        const response = await getTravellers(token, uid);
        console.log(response.data);
        const data = response.data.travelers.filter(
          (traveller) => traveller.id
        );
        console.log(data);
        setTraveller(data);
      } catch (error) {
        console.log(error);
      }
    };

    travellers();
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      const verify = verifyExpireTokenRefresh();
      if (verify) navigate("/");

      const token = getTokenRefresh();
      const response = await createTraveller(
        token,
        values.name.toUpperCase(),
        values.age,
        values.dni.toUpperCase(),
        values.country.toUpperCase()
      );
      const trave = {
        id: response?.data?.id,
        name: values.name.toUpperCase(),
        age: values.age,
        document: values.dni.toUpperCase(),
        country: values.country.toUpperCase(),
      };
      setTraveller([...traveller, trave]);
      showSnackbar("USUARIO AGREGADO");
      setIsLoading(false);
    } catch (err) {
      showSnackbar("ERROR NO SE AGREGO AL USUARIO");
      setIsLoading(false);
      console.log(err);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const showSnackbar = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const handleCloseSnk = () => setStateSnk(false);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userAge, setUserAge] = useState("");
  const [userDocument, setUserDocument] = useState("");

  const editTraveller = async () => {
    try {
      setIsLoading(true);
      const token = getTokenRefresh();
      const putTraveller = {
        id: userId,
        name: userName,
        document: userDocument,
        country: userCountry,
        age: userAge,
      };
      await updateTraveller(token, putTraveller);
      const index = traveller.findIndex(
        (travel) => travel.id === putTraveller.id
      );
      traveller[index] = putTraveller;
      showSnackbar("DATOS DE USUARIO ACTUALIZADO");
      handleCloseEditModal();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("ERROR NO SE LOGRO ACTUALIZAR DATOS", "error");
      console.log(error);
    }
  };

  const eliminateTraveller = async (id) => {
    try {
      setIsLoading(true);
      const token = getTokenRefresh();
      await deleteTraveller(token, id);
      const index = traveller.findIndex((travel) => travel.id === id);
      traveller.splice(index, 1);
      showSnackbar("DATOS DE USUARIO ACTUALIZADO");
      handleCloseEditModal();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("ERROR NO SE LOGRO ACTUALIZAR DATOS", "error");
      console.log(error);
    }
  };

  return (
    <div>
      <TopMenu />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm" sx={{ p: 1 }}>
          <Header title={t("")} subtitle={t("travel_list")} />
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  gap="30px"
                  onSubmit={handleSubmit}
                  sx={{ mt: 3, margin: "5px" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10}>
                      <TextField
                        value={values.name}
                        fullWidth
                        type="text"
                        label={t("name")}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="name"
                        error={!!touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        value={values.age}
                        fullWidth
                        type="number"
                        label={t("age")}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="age"
                        error={!!touched.age && !!errors.age}
                        helperText={touched.age && errors.age}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        value={values.country}
                        fullWidth
                        type="text"
                        label={t("country")}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="country"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        error={!!touched.country && !!errors.country}
                        helperText={touched.country && errors.country}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          DNI
                        </InputLabel>
                        <OutlinedInput
                          value={values.dni}
                          name="dni"
                          maxRows={1}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          id="control-dni"
                          inputProps={{
                            style: { textTransform: "uppercase" },
                            maxLength: 16,
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle visibility"
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                onClick={async (e) => {
                                  if (values.dni.length === 8) {
                                    try {
                                      const res = await getSunatDni(values.dni);
                                      console.log(res);
                                      values.name = `${res?.data?.nombres} ${res?.data?.apellidoPaterno} ${res.data?.apellidoMaterno}`;
                                      showSnackbar("Persona Encontrada");
                                    } catch (error) {
                                      showSnackbar(
                                        "DNI no encontrado",
                                        "error"
                                      );
                                    }
                                  } else {
                                    showSnackbar("DNI de 8 digitos", "error");
                                  }
                                }}
                              >
                                <SendIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                          label="DNI"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        fullWidth
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isLoading}
                      >
                        {t("add")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                <SnackBar
                  stateSnk={stateSnk}
                  vertical={vertical}
                  horizontal={horizontal}
                  duration={4000}
                  handleClose={handleCloseSnk}
                  severity={severity}
                  messageSnk={messageSnk}
                />
              </form>
            )}
          </Formik>
        </Container>

        <Container component="main" maxWidth="md" sx={{ p: 1 }}>
          {traveller && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t("ID")}</StyledTableCell>
                    <StyledTableCell>{t("name").toUpperCase()}</StyledTableCell>
                    <StyledTableCell align="center">
                      {t("age").toUpperCase()}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t("dni").toUpperCase()}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {t("country").toUpperCase()}
                    </StyledTableCell>
                    <StyledTableCell align="center"></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {traveller.map((row, index) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.name.toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.age}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.document}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.country.toUpperCase()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            setUserId(row.id);
                            setUserName(row.name);
                            setUserDocument(row.document);
                            setUserAge(row.age);
                            setUserCountry(row.country);
                            handleOpenEditModal();
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          color="error"
                          onClick={(e) => {
                            e.preventDefault();
                            eliminateTraveller(row.id);
                          }}
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Container>
        
        <Modal open={openEditModal} onClose={handleCloseEditModal}>
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField
                  value={userName}
                  fullWidth
                  type="text"
                  label={t("name")}
                  onChange={(e) => setUserName(e.target.value)}
                  name="name"
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  value={userAge}
                  fullWidth
                  type="number"
                  label={t("age")}
                  onChange={(e) => setUserAge(e.target.value)}
                  name="age"
                />
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  value={userCountry}
                  fullWidth
                  type="text"
                  label={t("country")}
                  name="country"
                  onChange={(e) => setUserCountry(e.target.value)}
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    DNI
                  </InputLabel>
                  <OutlinedInput
                    value={userDocument}
                    name="dni"
                    maxRows={1}
                    id="control-dni"
                    inputProps={{
                      style: { textTransform: "uppercase" },
                      maxLength: 16,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle visibility"
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          onClick={async (e) => {
                            if (userDocument.length === 8) {
                              try {
                                const res = await getSunatDni(e.target.value);
                                setUserName(
                                  `${res?.data?.nombres} ${res?.data?.apellidoPaterno} ${res.data?.apellidoMaterno}`
                                );
                                setUserDocument(e.target.value);
                                showSnackbar("Persona Encontrada");
                              } catch (error) {
                                showSnackbar("DNI no encontrado", "error");
                              }
                            } else {
                              showSnackbar("DNI de 8 digitos", "error");
                            }
                          }}
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="DNI"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    editTraveller();
                  }}
                >
                  {t("ACTUALIZAR")}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCloseEditModal();
                  }}
                >
                  {t("cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </ThemeProvider>
      <Footer />
    </div>
  );
};

export default Traveller;
