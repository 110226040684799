const DRIVER_STORE = "DriverStore";

export const saveDriverStore = (data) => {
  window.localStorage.setItem(DRIVER_STORE, JSON.stringify(data));
};

export const getDriverStore = () => {
  try {
    if (!localStorage) return null;

    const data = window.localStorage.getItem(DRIVER_STORE);

    if (!data) return null;

    const driver = JSON.parse(data);
    return driver;
  } catch (error) {
    return null;
  }
};

export const removeDriverStore = () => {
  window.localStorage.removeItem(DRIVER_STORE);
};
