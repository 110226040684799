import { ref, update } from "firebase/database";
import { db } from "../../../../../firebase";

export const UpdateCounterOffer = async (idBooking, idDriver, price) => {
  try {
    const refPath = `bookingNow/${idBooking}`;
    const refDB = ref(db, refPath);
    const newPrice = {
      idDriver: idDriver,
      price: Number(price),
    };

    await update(refDB, newPrice);
  } catch (error) {
    console.log(error);
  }
};
