import { URL_BOOKING } from "../../helpers/endpoint";
import { baseBackend } from ".././base/baseUrl";

export const getBooking = async (token, id) => {
  return await baseBackend.get(`${URL_BOOKING}/client/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
