import { REGEX_PHONE } from "../../../helpers/regex";

export const registerValues = {
  name: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  genre: "",
  password: "",
  confirmPwd: "",
};

export const registerSchema = (yup) => {
  return {
    name: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    phone: yup
      .number()
      .positive()
      .min(99999, "Min 6 digitos")
      .max(999999999999, "Max 12 digitos")
      .required("required"),
    address: yup.string().required("required"),
    password: yup
      .string()
      .min(6, "Min 6")
      .max(20, "Max 20")
      .required("required"),
    confirmPwd: yup.string().required("required"),
  };
};
