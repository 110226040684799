import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
  },
});

const Contact = ({ invoice }) => (
  <View style={styles.headerContainer}>
    <Text>
      <Text style={{ fontFamily: "Helvetica-Bold" }}>Razon Social:</Text>
      {invoice.address}
    </Text>
    <Text>
      <Text style={{ fontFamily: "Helvetica-Bold" }}>Contacto: </Text>
      {invoice.phone}
    </Text>
    <Text>
      <Text style={{ fontFamily: "Helvetica-Bold" }}>Email: </Text>
      {invoice.email}
    </Text>
  </View>
);

export default Contact;
