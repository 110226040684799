export const convertDate = (date, timeComplete) => {
  const [day, month, year] = date.split("/");
  const [time, op] = timeComplete.split(" ");
  const [hour, min] = time.split(":");

  const dateFormat = new Date(
    year,
    Number(month) - 1,
    day,
    op === "AM"
      ? Number(hour) === 12
        ? 0
        : Number(hour)
      : Number(hour) === 12
      ? 12
      : Number(hour) + 12,
    min
  );

  return dateFormat;
};
