import { createSlice } from "@reduxjs/toolkit";

export const bookingSlice = createSlice({
  name: "bookings",
  initialState: {},
  reducers: {
    addBooking: (state, action) => {
      //state.push(state: action.payload);
      state.data = action.payload;
      //state = action.payload
    },
    deleteBooking: (state, action) => {
      delete state.data;
      //state.splice(action.payload, 1);
      // const bookingFound = state.find(
      //   (booking) => booking.id === action.payload
      // );
      // if (bookingFound) {state.splice(state.indexOf(0), 1);}
    },
  },
});

export const { addBooking, deleteBooking } = bookingSlice.actions;
export default bookingSlice.reducer;
