import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  CardHeader,
  Grid,
  Modal,
  Box,
  Stack,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import Header from "../../../../components/header/Header";
import {
  decodeClientToken,
  decodeTokenRefresh,
  getClientToken,
  getTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import { getBooking } from "../../../../api/booking/booking";
import { useNavigate } from "react-router-dom";
import { ref, update } from "firebase/database";
import { db } from "../../../../firebase";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { GenerateOpenpayUseCase } from "../../../../api/openpay/GenerateOpenpayUseCase";

const HistoryTotal = ({ client }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [booking, setBooking] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const curren = ["", "PEN", "USD"];

  const [uid, setUid] = useState("");

  const token = getTokenRefresh();
  const id = decodeTokenRefresh(token);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const travelStatus = {
    1: "ASIGNADO",
    2: "EN RUTA",
    3: "FINALIZADO",
  };

  useEffect(() => {
    const getListBooking = async () => {
      try {
        const response = await getBooking("token", id);
        console.log(response);
        // setBooking(response.data);
        setBooking([]);
        Object.values(response.data).map((item, idx) => {
          let total = Number(item.price_total);
          item.advanceList.map((adv, idx) => {
            total = total - Number(adv.amount);
          });
          item.price_total = total;
          setBooking((oldData) => [...oldData, item]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getListBooking();
  }, []);

  const [payment, setPayment] = useState(false);
  const handleClosePayment = () => setPayment(false);
  const handleOpenPayment = () => setPayment(true);
  const [bookingLater, setBookingLater] = useState(null);

  const generateOpenPayForm = async (booking) => {
    try {
      setIsLoading(true);
      const baseUrl = "https://booking.taximundo.com/client";
      // const baseUrl = "http://localhost:3001/client";
      const redirectUrl = `${baseUrl}/transfer/${curren[booking.currency]}`;

      const dataToken = getClientToken();
      const { name, lastname, phone, email } = decodeClientToken(dataToken);
      const orderID = `${booking.order_number}-${Date.now()}`;

      let total = Number(booking.price_total);
      booking.advanceList.map((adv) => {
        total = total - Number(adv.amount);
      });
      booking.price_total = total;

      const bookingOpenPay = {
        method: "card",
        amount: Number(total),
        currency: curren[booking.currency],
        description: `${booking.order_number}. PAGO POR SERVICIO DE TRANSPORTE`,
        order_id: orderID,
        confirm: "false",
        send_email: "false",
        redirect_url: redirectUrl,
        due_date: dateOpenpayFormat(), //"2023-12-09T19:22:00",
        customer: {
          name: name,
          last_name: lastname,
          phone_number: phone,
          email: email,
        },
      };

      const response = await GenerateOpenpayUseCase(bookingOpenPay);

      if (response.message === "success") {
        const paymentUrl = response.data.payment_method.url;
        const transactionId = response.data.id;
        const orderId = response.data.order_id;
        window.open(paymentUrl, "_blank");
        const openpayData = {
          transaction_id: transactionId,
          order_id: orderId,
          createBy: "B",
        };
        const fbUrl = `bookingNow/${booking.uuid}/openpay`;
        await update(ref(db, fbUrl), openpayData);
      } else {
        showSnackbar("ERROR: FALLO DE OPENPAY", "error");
      }
      handleClosePayment();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      handleClosePayment();
      setIsLoading(false);
    }
  };

  const dateOpenpayFormat = () => {
    var fecha = new Date();

    var año = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    var hora = ("0" + fecha.getHours()).slice(-2);
    var minuto = ("0" + fecha.getMinutes()).slice(-2);
    var segundo = ("0" + fecha.getSeconds()).slice(-2);

    var formatoFecha =
      año + "-" + mes + "-" + dia + "T" + hora + ":" + minuto + ":" + segundo;

    return formatoFecha;
  };

  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const handleCloseSnk = () => setStateSnk(false);

  const showSnackbar = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={""} subtitle={t("history")} />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          {t("create new order")}
        </Button>
      </Box>
      {booking.length > 0 ? (
        booking.map((booking, index) => (
          <Card key={index} sx={{ border: 1, margin: "1px -3px 2px -3px" }}>
            <CardHeader
              sx={{ mt: -1 }}
              title={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  TM-{booking.order_number}
                </Typography>
              }
              action={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  {booking.date} - {booking.time}
                </Typography>
              }
            />
            <CardContent sx={{ mt: -4 }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h7"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    A {t("origin")}: {booking.origin_name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h7"
                    color={"error"}
                    sx={{ fontWeight: "bold" }}
                  >
                    B {t("destination")}: {booking.destination_name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {t("driver")}: {booking.driver_name}{" "}
                    {booking.driver_lastname}
                  </Typography>
                </Grid>

                <Grid item xs={4} sm={3}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <p style={{ fontWeight: "bold", color: "blue" }}>
                        {t("vehicle")}:
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p style={{ fontWeight: "bold" }}>{booking.bodywork}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <p style={{ fontWeight: "bold", color: "blue" }}>
                        {t("plate")}:
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p style={{ fontWeight: "bold" }}>{booking.plate}</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <p style={{ fontWeight: "bold", color: "blue" }}>
                        {t("persons")}
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <p style={{ fontWeight: "bold" }}>{booking.persons}</p>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {t("notes")}: {booking.notes}
                  </Typography>
                </Grid>
                {booking.advanceList.length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      sx={{ fontSize: 15, fontWeight: "bold", color: "black" }}
                    >
                      PAGOS
                    </Typography>
                  </Grid>
                )}
                {booking.advanceList.map((item, idx) => {
                  return (
                    <Grid key={idx} item xs={12}>
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {item.currency} {item.amount} {item.status}{" "}
                        {item.operation_date}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>

            <CardActions sx={{ mt: -2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    <DriveEtaOutlinedIcon />
                    &nbsp;
                    {travelStatus[booking.travel_status_id]}
                  </p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    <PriceCheckOutlinedIcon />
                    &nbsp;
                    {booking.openpay
                      ? booking.openpay?.status === "pending"
                        ? t("pending")
                        : t("paid")
                      : t("pending")}
                  </p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    {curren[booking.currency]} {booking.price_total}
                  </p>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                  <Button
                    fullWidth
                    size="small"
                    color="info"
                    variant="contained"
                    sx={{ fontWeight: "bold", background: "#128C7E" }}
                    href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${booking.order_number},%0A *Origen:* ${booking.origin_name}, *Destino:* ${booking.destination_name} `}
                    target={"_blank"}
                    onClick={(e) => {
                      console.log(booking.uuid);
                      setUid(booking.uuid);
                    }}
                  >
                    {t("consult")}&nbsp;
                    <WhatsAppIcon />
                  </Button>
                </Grid>

                {booking.price_total > 0 && (
                  <Grid item xs={6} sm={3} md={2}>
                    <Button
                      fullWidth
                      size="small"
                      color="primary"
                      variant="contained"
                      sx={{ fontWeight: "bold" }}
                      onClick={(e) => {
                        handleOpenPayment();
                        setBookingLater(booking);
                      }}
                    >
                      {t("pay")}&nbsp;
                      <ContactlessOutlinedIcon />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardActions>
          </Card>
        ))
      ) : (
        <div>{t("no_history")}</div>
      )}

      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleCloseSnk}
        severity={severity}
        messageSnk={messageSnk}
      />
      <Modal open={payment} onClose={handleClosePayment}>
        <Box sx={style}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold" }}
            textAlign={"center"}
          >
            PAGAR PEDIDO?
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              fullWidth
              size="small"
              color="primary"
              variant="contained"
              sx={{ fontWeight: "bold" }}
              disabled={isLoading}
              onClick={(e) => {
                generateOpenPayForm(bookingLater);
              }}
            >
              CONFIRMAR
            </Button>
            <Button
              fullWidth
              size="small"
              color="error"
              variant="contained"
              sx={{ fontWeight: "bold" }}
              onClick={handleClosePayment}
            >
              CANCELAR
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default HistoryTotal;
