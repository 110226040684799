import { authRiderGoogle } from "../repository/login.repository.js";

export const authRiderGoogleUseCase = async (rider) => {
  try {
    const response = await authRiderGoogle(rider);
    return response;
  } catch (error) {
    return null;
  }
};
