import { generateOpenPayURL } from "./openpay";

export const GenerateOpenpayUseCase = async (data) => {
  try {
    const resp = await generateOpenPayURL(data);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
