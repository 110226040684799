import { authRiderFacebook } from "../repository/login.repository";

export const authRiderFacebookUseCase = async (rider) => {
  try {
    const response = await authRiderFacebook(rider);
    return response;
  } catch (error) {
    return error.response;
  }
};
