import axios from "axios";
import { baseBackend } from "../../../../api/base/baseUrl";

const URL_GOOGLE = "/api/v1/client-google";
const URL_FACEBOOK = "/api/v1/client-facebook";
const URL_REGISTER = "/api/v1/client-update";
const base = "https://www.googleapis.com";
const baseGoogle = axios.create({
  baseURL: base,
});

export const loginWithGoogle = async (token) => {
  return await baseGoogle.get(`/oauth2/v1/userinfo?access_token=${token}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const authRiderGoogle = async (rider) => {
  return await baseBackend.post(`${URL_GOOGLE}`, JSON.stringify({ rider }), {
    headers: { "Content-Type": "application/json" },
  });
};

export const authRiderFacebook = async (rider) => {
  return await baseBackend.post(`${URL_FACEBOOK}`, JSON.stringify({ rider }), {
    headers: { "Content-Type": "application/json" },
  });
};

export const updateRegister = async (rider) => {
  return await baseBackend.put(`${URL_REGISTER}`, JSON.stringify({ rider }), {
    headers: { "Content-Type": "application/json" },
  });
};
