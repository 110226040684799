import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";

import { Copyright } from "../../../components/copyright/Copyright";
import AlertNotification from "../../../components/notificarions/AlertNotification";
import {
  getResetPassword,
  postResetPassword,
} from "../../../api/resetPassword";
import SnackBar from "../../../components/snackbar/SnackBar";

const theme = createTheme();

const ResetPassword = () => {
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const checkoutSchema = yup.object().shape({
    password: yup.string().required("Password Required"),
  });

  const initialValues = {
    password: "",
    cpassword: "",
  };

  useEffect(() => {
    const resetPassword = async (id, token) => {
      try {
        const response = await getResetPassword(id, token);
        setEmail(response.data.email);
      } catch (error) {
        setMessage(error);
      }
    };
    resetPassword(id, token);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => {
    setStateSnk(false);
  };
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleFormSubmit = async (values) => {
    try {
      if (values.password === values.cpassword) {
        await postResetPassword(id, token, values.password);
        snackBarDialog("LA CONTRASEÑA HA SIDO CAMBIADA");
        setIsLoading(false);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      } else {
        snackBarDialog("LOS CAMPOS DE CONTRASEÑA DEBEN SER IGUALES", "error");
      }
    } catch (err) {
      snackBarDialog("ERROR, NO SE HA PODIDO CAMBIAR LA CONTRASEÑA", "error");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={`../../assets/logo.png`}
            style={{ width: 152, height: 152 }}
            alt="Logo"
          />

          <Typography component="h1" variant="h5">
            TaxiMundo.com
          </Typography>
          <Box sx={{ mt: 1, width: 450 }}>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* <Box display="flex">
                    <CodeSuccess />
                  </Box> */}

                  {message ? (
                    <AlertNotification
                      description={message}
                      alertType="error"
                    />
                  ) : (
                    <div>
                      <h2>{email}</h2>
                      {messageSuccess ? (
                        <AlertNotification
                          description={t("changeSuccessfull")}
                          alertType="success"
                        />
                      ) : (
                        <div>
                          <Box display="grid" gap="5px" sx={{ margin: "4px" }}>
                            <TextField
                              value={values.password}
                              margin="normal"
                              type="password"
                              required
                              fullWidth
                              id="password"
                              label={t("newPassword")}
                              name="password"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={!!touched.password && !!errors.password}
                              helperText={touched.password && errors.password}
                            />
                          </Box>
                          <Box display="grid" gap="5px" sx={{ margin: "4px" }}>
                            <TextField
                              value={values.cpassword}
                              margin="normal"
                              type="password"
                              required
                              fullWidth
                              id="cpassword"
                              label={t("confirmNewPassword")}
                              name="cpassword"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={!!touched.cpassword && !!errors.cpassword}
                              helperText={touched.cpassword && errors.cpassword}
                            />
                          </Box>
                          <Box display="flex" justifyContent="center" mt="10px">
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ mt: 2, mb: 2 }}
                              disabled={isLoading}
                            >
                              {t("changePassword")}
                            </Button>
                          </Box>
                        </div>
                      )}
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={5000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default ResetPassword;
