import { updateRegister } from "../repository/login.repository";

export const updateRegisterUseCase = async (rider) => {
  try {
    const response = await updateRegister(rider);

    return response;
  } catch (error) {
    return error.response;
  }
};
