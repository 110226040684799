import React from "react";
import { Copyright } from "../copyright/Copyright";
import "./footer.css";
import logo from "../../assets/logo-main.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer>
        <div className="container grid1">
          <div className="box">
            <img src={logo} alt="" width="150" height="70" />
            <p>{t("footerDescription")}</p>
          </div>

          <div className="box">
            <h2>Links</h2>
            <ul>
              <li>
                <Link to="/" rel="noopener noreferrer" >
                  Home
                </Link>
              </li>
              <li>
                <a href="https://taximundo.com/about/index.html" target="_blank" rel="noopener noreferrer">
                  About
                </a>
              </li>
              <li>
                <a href="https://taximundo.com/support/index.html" target="_blank" rel="noopener noreferrer">
                  Contact
                </a>
              </li>
              <li>
                <a href="https://taximundo.com/policies/faq/index.html" target="_blank" rel="noopener noreferrer">
                  Faq's
                </a>
              </li>
              <li>
                <a href="https://taximundo.com/policies/policy/index.html" target="_blank" rel="noopener noreferrer">
                  Policy
                </a>
              </li>
            </ul>
          </div>

          <div className="box">
            <h2>{t("contact")}</h2>
            <div className="icon">
              <label>☏ {t("call_center")}: +51 54 200 900</label>
            </div>
            <div className="icon">
              <label>📱 Movil (WhatsApp): <a href="tel:+51958200900">958 200 900*</a></label>
            </div>
            <div className="icon">
              <label>📱 Movil (WhatsApp): <a href="tel:+51951200900">951 200 900</a></label>
            </div>
            <div className="icon">
              <label>📧 Email: <a href="mailto:info@taximundo.com">info@taximundo.com</a></label>
            </div>
          </div>
        </div>
        <div className="legal container">
          <Copyright />
        </div>
      </footer>
    </>
  );
};

export default Footer;
