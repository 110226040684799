import { Navigate, Outlet } from "react-router-dom";
import {
  deleteToken,
  verifyExpireTokenRefresh,
} from "../../../helpers/token/refreshToken";

//entras a register/login/reset/validarion
export const ProtectedRoute = ({ children, redirectTo = "/client" }) => {
  const token = verifyExpireTokenRefresh();
  if (token) {
    deleteToken("ctknre");
    deleteToken("cdttkn");
    return children ? children : <Outlet />;
  }

  return <Navigate to={redirectTo} />;
};

//entras a profile booking traveller
export const ProtectedClientRoute = ({ children, redirectTo = "/" }) => {
  const token = verifyExpireTokenRefresh();
  if (token) {
    deleteToken("ctknre");
    deleteToken("cdttkn");
    return <Navigate to={redirectTo} />;
  }

  return children ? children : <Outlet />;
};
