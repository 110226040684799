import axios from "axios";

const baseFcm = axios.create({
  baseURL: "https://alert.usa.pe/",
});

export const sendPushNotificationByToken = async (token, title, body) => {
  return await baseFcm.post(
    "api/v1/send",
    JSON.stringify({ token, title, body }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const sendPushNotificationByTokens = async (tokens, title, body) => {
  return await baseFcm.post(
    "api/v1/tokens",
    JSON.stringify({ tokens, title, body }),
    { headers: { "Content-Type": "application/json" } }
  );
};
