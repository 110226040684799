import React, { useEffect, useState } from "react";
import { db } from "../../../../firebase";
import { set, ref, onValue, remove, update } from "firebase/database";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  CardHeader,
  Grid,
  Modal,
  Box,
  Stack,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import Header from "../../../../components/header/Header";
import {
  decodeClientToken,
  decodeTokenRefresh,
  getClientToken,
  getTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import { generateOpenPayURL } from "../../../../api/openpay/openpay";
import { useNavigate } from "react-router-dom";

const HistoryBookingNow = ({ client }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [booking, setBooking] = useState([]);
  const model = ["", "Sedan", "SUV", "Minivan"];
  const curren = ["", "PEN", "USD"];

  const [open, setOpen] = React.useState(false);

  const [uid, setUid] = useState("");
  const [bookingLater, setBookingLater] = useState();
  const handleClose = () => setOpen(false);

  const token = getTokenRefresh();
  const id = decodeTokenRefresh(token);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  //pedidos que estan en firebase
  //pedidos
  useEffect(() => {
    const starCountRef = ref(db, `bookingNow`);
    onValue(starCountRef, (snapshot) => {
      let data = snapshot.val();
      if (data != null) {
        setBooking([]);
        Object.values(data).map((booking) => {
          if (booking.client === id && booking.booking_type == 1) {
            setBooking((oldBooking) => [...oldBooking, booking]);
          }
        });
      }
    });
  }, []);

  const currency = { 1: "PEN", 2: "USD" };

  const dateOpenpayFormat = () => {
    var fecha = new Date();

    var año = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agregar un cero inicial si es necesario
    var dia = ("0" + fecha.getDate()).slice(-2); // Agregar un cero inicial si es necesario
    var hora = ("0" + fecha.getHours()).slice(-2); // Agregar un cero inicial si es necesario
    var minuto = ("0" + fecha.getMinutes()).slice(-2); // Agregar un cero inicial si es necesario
    var segundo = ("0" + fecha.getSeconds()).slice(-2); // Agregar un cero inicial si es necesario

    var formatoFecha =
      año + "-" + mes + "-" + dia + "T" + hora + ":" + minuto + ":" + segundo;

    return formatoFecha;
  };

  const generateOpenPayForm = async (booking) => {
    try {
      const baseUrl = "http://booking.taximundo.com/client";
      const redirectUrl = `${baseUrl}/transfer/${currency[booking.currency]}`;

      const dataToken = getClientToken();
      const { name, lastname, phone, email } = decodeClientToken(dataToken);
      const orderID = `${booking.counterBooking}-${Date.now()}`;

      const bookingOpenPay = {
        method: "card",
        amount: Number(booking.total),
        currency: currency[booking.currency],
        description: "PAGO POR SERVICIO DE TRANSPORTE",
        order_id: orderID,
        confirm: "false",
        send_email: "false",
        redirect_url: redirectUrl,
        due_date: dateOpenpayFormat(),
        customer: {
          name: name,
          last_name: lastname,
          phone_number: phone,
          email: email,
        },
      };

      const response = await generateOpenPayURL(bookingOpenPay);
      const data = response.data.data;
      window.open(data.payment_method.url, "_blank");

      const openpayData = {
        transaction_id: data.id,
        order_id: data.order_id,
        createBy: "B",
      };
      await update(ref(db, `bookingNow/${booking.uuid}/openpay`), openpayData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("")} subtitle={t("now")} />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          {t("create new order")}
        </Button>
      </Box>

      {booking.length > 0 ? (
        booking.map((booking, index) => (
          <Card key={index} sx={{ border: 1, margin: "1px -3px 2px -3px" }}>
            <CardHeader
              sx={{ mt: -1 }}
              title={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  TM-{booking.counterBooking}
                </Typography>
              }
              action={
                <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                  {booking.date} - {booking.time}
                </Typography>
              }
            />
            <CardContent sx={{ mt: -4 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    A Origen: {booking.origin.name}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"error"}
                    sx={{ fontWeight: "bold" }}
                  >
                    B Destino: {booking.destination.name}
                  </Typography>
                </Grid>
                {booking.driver ? (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Conductor: {booking.driver.name} {booking.driver.lastname}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Vehicle: {booking.driver.brand} {booking.driver.model} -
                      {booking.driver.plate}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Vehicle: {model[booking.vehicle]}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Persons: {booking.persons}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Nota: {booking.note}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ mt: -2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    <DriveEtaOutlinedIcon />
                    &nbsp;
                    {booking.openpay
                      ? booking.openpay?.status === "pending"
                        ? "PENDIENTE"
                        : "PAGADO"
                      : "PENDIENTE"}
                  </p>
                  {/* <Button
                    fullWidth
                    size="small"
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#ffffff",
                      border: 1,
                      borderColor: "black",
                    }}
                  >
                    {booking.openpay
                      ? booking.openpay?.status === "pending"
                        ? "PENDIENTE"
                        : "PAGADO"
                      : "PENDIENTE"}
                    &nbsp;
                    <ContactlessOutlinedIcon />
                  </Button> */}
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{
                      fontWeight: "bold",
                      background: "white",
                      borderRadius: 3,
                      borderStyle: "solid",
                      borderColor: "black",
                      borderWidth: 1,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "black",
                      width: "100%",
                      height: "100%",
                      fontSize: 15,
                    }}
                  >
                    {curren[booking.currency]} {booking.total}
                  </p>
                  {/* <Button
                    fullWidth
                    size="small"
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "#ffffff",
                      border: 1,
                      borderColor: "black",
                    }}
                  >
                    {curren[booking.currency]} {booking.total}
                  </Button> */}
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Button
                    fullWidth
                    size="small"
                    color="info"
                    variant="contained"
                    sx={{ fontWeight: "bold", background: "#128C7E" }}
                    href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${booking.counterBooking},%0A *Cliente:* ${booking.clientName},%0A *Origen:* ${booking.origin.name}, *Destino:* ${booking.destination.name} `}
                    target={"_blank"}
                    onClick={(e) => {
                      setUid(booking.uuid);
                    }}
                  >
                    Consultar&nbsp;
                    <WhatsAppIcon />
                  </Button>
                </Grid>
                {booking.total > 0 &&
                  booking.openpay &&
                  booking.openpay.btnPay && (
                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="primary"
                        variant="contained"
                        sx={{ fontWeight: "bold" }}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setOpen(true);
                          setUid(booking.uuid);
                          setBookingLater(booking);
                        }}
                      >
                        PAGAR&nbsp;
                        <ContactlessOutlinedIcon />
                      </Button>
                      {/* CREAR LA FUNCIONALIDAD PARA PAGAR */}
                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            PAGAR PEDIDO?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                                generateOpenPayForm(bookingLater);
                              }}
                            >
                              CONFIRMAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                              }}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>
                  )}

                {/* {booking.total === 0 ? (
                  <Grid item xs={9} md={6}>
                    {" "}
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={6} md={2}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        sx={{ fontWeight: "bold" }}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setOpen(true);
                          setUid(booking.uuid);
                          booking.statusBooking = "Aceptado";
                          setBookingLater(booking);
                        }}
                      >
                        CONFIRMAR
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            Confirmar Reserva?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                console.log(uid);
                                console.log(bookingLater);
                                const refFire = `bookingNow/${uid}`;
                                set(ref(db, refFire), bookingLater);
                                setOpen(false);
                              }}
                            >
                              CONFIRMAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                              }}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <Button
                        size="small"
                        color="error"
                        variant="contained"
                        sx={{ fontWeight: "bold" }}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setOpen(true);
                          setUid(booking.uuid);
                          booking.statusBooking = "Rechazado";
                          setBookingLater(booking);
                        }}
                      >
                        RECHAZAR
                      </Button>
                      <Modal
                        open={openDelete}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            Desea Eliminar esta Reserva?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                console.log(uid);
                                console.log(bookingLater);
                                const refFire = `bookingNow/${uid}`;
                                set(ref(db, refFire), bookingLater);
                                setOpenDelete(false);
                              }}
                            >
                              ELIMINAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpenDelete(false);
                              }}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <Button
                        size="small"
                        color="info"
                        variant="contained"
                        sx={{ fontWeight: "bold", background: "#128C7E" }}
                        href={`https://api.whatsapp.com/send?phone=943061806&text=*Cliente:* ${client.name} ${client.lastname},%0A *Origen:* ${booking.origin.name}, *Destino:* ${booking.destination.name} `}
                        target={"_blank"}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setUid(booking.uuid);
                        }}
                      >
                        <WhatsAppIcon />
                        Negociar
                      </Button>
                    </Grid>
                  </>
                )} */}
              </Grid>
            </CardActions>
          </Card>
        ))
      ) : (
        <div>{t("no_orders")}</div>
      )}
    </>
  );
};

export default HistoryBookingNow;
