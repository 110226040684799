import { baseBackend } from "../base/baseUrl";

const URL_BACKUP = "/api/v1/backup-order/create";

export const createBackup = async (booking) => {
  return await baseBackend.post(URL_BACKUP, JSON.stringify({ booking }), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
