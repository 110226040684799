import { onValue, ref } from "firebase/database";
import { db } from "../../../../../firebase";

const timeFormat = (timeArrived) => {
  return timeArrived.split(" ")[0];
};

export const CounterOfferObserver = (employeeId, bookingId, callback) => {
  try {
    const dbPath = `CounterOffer/Normal`;
    const dbRef = ref(db, dbPath);

    const unsubscribe = onValue(
      dbRef,
      (snapshot) => {
        const resp = snapshot.val();
        let offerList = [];

        if (resp != null) {
          offerList = Object.values(resp)
            .filter(
              (offer) =>
                Number(employeeId) === Number(offer.idRider) &&
                bookingId === offer.idBooking
            )
            .sort(
              (a, b) => timeFormat(a.timeArrived) - timeFormat(b.timeArrived)
            );
        }
        callback(offerList);
      },
      (error) => {
        console.error(error);
      }
    );
    return unsubscribe;
  } catch (error) {
    console.error(error);
  }
};
