import { useEffect, useState } from "react";
import { db } from "../../../../firebase";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import { ref, onValue, remove, update, endAt } from "firebase/database";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Modal,
  Box,
  Stack,
  useTheme,
  OutlinedInput,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormLabel,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Header from "../../../../components/header/Header";
import {
  decodeTokenRefresh,
  getTokenRefresh,
} from "../../../../helpers/token/refreshToken";
import { useNavigate } from "react-router-dom";
import { convertDate } from "../../../../helpers/date/convertDate";
import GridDataTable from "./table/GridDataTable";
import { tokens } from "../../../../theme";
import HeaderPdf from "./pdf/elements/Header";
import Contact from "./pdf/elements/Contact";
import DocumentName from "./pdf/elements/DocumentName";
import Rider from "./pdf/elements/Rider";
import TableRider from "./pdf/elements/TableRider";

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";

import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import {
  buttonStyle,
  suggestionActive,
  suggestionInactive,
} from "./style/PendingLater.style";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./style/booking.css";
import { Formik } from "formik";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getModels } from "../../../../api/model";
import {
  dateFormat,
  formatDateTime,
  timeFormat,
} from "../../../../helpers/date/date";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { getListCountryUseCase } from "../booking/usecases/country/GetListCountryUseCase";
import { getListTourByCountry } from "../booking/data/repository/tourRepo";

const ListQuote = ({ client }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [booking, setBooking] = useState([]);
  const model = ["", "Sedan", "SUV", "Minivan"];
  // const curren = ["", "PEN", "USD"];
  const [modelApi, setModelApi] = useState([]);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [uid, setUid] = useState("");
  const [bookingLater, setBookingLater] = useState();
  const handleClose = () => setOpen(false);

  const token = getTokenRefresh();
  const id = decodeTokenRefresh(token);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const curren = {
    1: "PEN",
    2: "USD",
  };
  const typeTitle = {
    2: "TRASLADO",
    3: "TRANSLADO POR HORAS",
    4: "TOUR",
  };

  useEffect(() => {
    try {
      const starCountRef = ref(db, `bookingNow/`);
      onValue(starCountRef, (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          const compareByDate = (a, b) => {
            if (b.date != undefined && a.date != undefined) {
              const dateB = convertDate(b.date, b.time);
              const dateA = convertDate(a.date, a.time);
              return dateB - dateA;
            }
          };
          setBooking([]);
          Object.values(data)
            .sort(compareByDate)
            .map((_book) => {
              if (
                _book.client === id &&
                _book.booking_type != 1 &&
                _book.statusPayment != "PAGADO" &&
                !_book.cotizado
              ) {
                _book.currency = curren[_book.currency];
                _book.fullorigin = _book.origin.name;
                _book.fulldestination = _book.destination.name;
                setBooking((oldBooking) => [...oldBooking, _book]);
              }
            });
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [dataSelected, setDataSelected] = useState([]);
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #000",
    boxShadow: 20,
    width: "100%",
    p: 2,
    overflow: "scroll",
  };

  const columns = [
    {
      headerName: "Pedido",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
      renderCell: ({ row: { counterBooking } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              color: "white",
              background: "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {counterBooking}
          </div>
        );
      },
    },
    {
      headerName: "NOMBRE",
      field: "clientName",
      width: 130,
    },
    {
      headerName: "FECHA",
      field: "date",
      width: 80,
    },
    {
      headerName: "ORIGEN",
      field: "fullorigin",
      width: 250,
    },
    {
      headerName: "DESTINO",
      field: "fulldestination",
      width: 250,
    },
    {
      headerName: "TOTAL",
      field: "total",
      width: 100,
    },
  ];
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    let total = 0;
    let discount = 0;
    let advance = 0;

    Object.values(dataSelected).map((book) => {
      total += Number(book.total);
      discount += Number(book.discount);
      advance += Number(book.address ? book.advance : 0);
    });

    setTableData({});
    setTableData({
      id: "5df3180a09ea16dc4b95f910",
      invoice_no: "201906-28",
      company: "TAXIMUNDO",
      email: "info@taximundo.com",
      phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
      address: "Servicios Generales Trans Mundo E.I.R.L.",
      items: dataSelected,
      igv: 0,
      total: total,
      discount,
      advance,
      peaje: 0,
      subTotal: 0,
      from: 0,
      to: 0,
    });
  }, [dataSelected]);

  const [bookItem, setBookItem] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [cOrigin, setCOrigin] = useState("");
  const [cDestination, setCDestination] = useState("");
  const [bookType, setBookType] = useState(1);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [timeEnd, setTimeEnd] = useState();
  const [persons, setPersons] = useState(0);
  const [vehicle, setVehicle] = useState(1);
  const [note, setNote] = useState("");

  useEffect(() => {
    const models = async () => {
      try {
        const response = await getModels();
        const data = response.data.filter((model) => model.id);
        setModelApi(data);
      } catch (error) {
        console.log(error);
      }
    };
    models();
  }, []);

  const formatterTimePicker = (date, time) => {
    const [day, month, year] = date.split("/");
    const [hourMin, amPm] = time.split(" ");
    const [hour, minute] = hourMin.split(":");
    const realHour =
      amPm == "AM" ? hour : hour == 12 ? hour : parseInt(hour) + 12;
    return new Date(year, month - 1, day, realHour, minute);
  };

  const handSelect = async (value, setAddress, setCoordinate) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddress(value);
    setCoordinate(ll);
  };

  const checkTime = () => {
    const today = new Date();
    const { day, month, year, hour, minute } = formatDateTime(
      date.$d ?? date,
      time.$d ?? time
    );

    const dateOrder = new Date(year, month, day, hour, minute, 0);
    const diffInMillisec = dateOrder.getTime() - today.getTime();
    const diffInMin = diffInMillisec / (1000 * 60);

    if (diffInMin <= 1440) {
      snackBarDialog("TODA RESERVA DEBE SER MAYOR O IGUAL A 1 DIA", "error");
      return;
    }

    return true;
  };

  const updateBookingLater = async (e, item) => {
    e.preventDefault();
    try {
      if (checkTime()) {
        const refPath = `bookingNow/${item.uuid}`;
        const refDB = ref(db, refPath);

        let dateend = "";
        let timeend = "";
        if (item.booking_type == 3) {
          dateend = dateEnd.$d ? dateFormat(dateEnd.$d) : dateFormat(dateEnd);
          timeend = timeEnd.$d ? timeFormat(timeEnd.$d) : timeFormat(timeEnd);
        }

        const data = {
          booking_type: bookType,
          origin: {
            lat: cOrigin.lat,
            lng: cOrigin.lng,
            name: origin,
          },
          destination: {
            lat: cDestination.lat,
            lng: cDestination.lng,
            name: destination,
          },
          date: date.$d ? dateFormat(date.$d) : dateFormat(date),
          time: time.$d ? timeFormat(time.$d) : timeFormat(time),
          dateEnd: dateend,
          timeEnd: timeend,
          persons: Number(persons) ?? 1,
          vehicle: Number(vehicle) ?? 1,
          note: note.toUpperCase() ?? "",
          tour_id: tour.tour_id ?? "",
          tour_description: tour?.tour_description ?? "",
        };

        console.log(data);
        await update(refDB, data);
        handleCloseEdit();
        snackBarDialog("DATOS ACTUALIZADOS");
      }
    } catch (error) {
      console.log(error);
      snackBarDialog("SELECCIONE LA FECHA Y HORA", "error");
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountrys = async () => {
      try {
        const response = await getListCountryUseCase();
        setCountries([]);
        setCountries(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCountrys();
  }, []);

  //SELECCIONA UN PAIS TRAER LISTA DE TOUR
  const [tour, setTour] = useState(null);
  const [tours, setTours] = useState([]);

  useEffect(() => {
    if (country) {
      const getListTour = async () => {
        try {
          const response = await getListTourByCountry(country.id);
          setTours([]);
          setTours(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      getListTour();
    }
  }, [country]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("")} subtitle={t("quotation")} />
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleOpenModal()}
            sx={{ marginRight: 1 }}
          >
            <CloudDownloadOutlinedIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            <FiberNewOutlinedIcon /> {t("create new order")}
          </Button>
        </Box>
      </Box>

      {booking.length > 0 ? (
        booking.map((booking, index) => (
          <Card key={index} sx={{ border: 1, margin: "1px -3px 2px -3px" }}>
            <CardContent>
              <Grid container>
                <Grid item xs={4} sm={6}>
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    TM-{booking.counterBooking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={booking.booking_type === 3 ? 3 : 6}
                  textAlign={"end"}
                >
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    {booking.date} - {booking.time}
                  </Typography>
                </Grid>
                {booking.booking_type === 3 && (
                  <Grid item xs={12} sm={3} textAlign={"end"}>
                    <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                      {booking.dateEnd} - {booking.timeEnd}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    A Origen: {booking.origin.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h7"
                    color={"error"}
                    sx={{ fontWeight: "bold" }}
                  >
                    B Destino: {booking.destination.name}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Vehicle: {model[booking.vehicle]}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Persons: {booking.persons}
                  </Typography>
                </Grid>

                {booking.dateEnd && booking.timeEnd && (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Hasta: {booking.dateEnd} {booking.timeEnd}
                    </Typography>
                  </Grid>
                )}

                {booking.tour_description && (
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Tour: {booking.tour_description}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Nota: {booking.note}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions sx={{ mt: -2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <p style={buttonStyle}>
                    {booking.openpay
                      ? booking.openpay?.status === "pending"
                        ? "PENDIENTE"
                        : "PAGADO"
                      : "PENDIENTE"}
                    &nbsp;
                    <DriveEtaOutlinedIcon />
                  </p>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <div style={{ ...buttonStyle, display: "block" }}>
                    <p style={{ fontSize: 11, color: "green" }}>Precio</p>
                    <p>
                      {booking.currency} {booking.total}
                    </p>
                  </div>
                </Grid>
                {booking.discount && booking.discount > 0 ? (
                  <Grid item xs={6} sm={3} md={2}>
                    <div style={buttonStyle}>
                      <p style={{ fontSize: 11, color: "green" }}>Descuento</p>
                      <p>
                        {booking.currency} {booking.discount}
                      </p>
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}

                {booking.advance && booking.advance > 0 ? (
                  <Grid item xs={6} sm={3} md={2}>
                    <div style={buttonStyle}>
                      <p style={{ fontSize: 11, color: "green" }}>Adelanto</p>
                      <p>
                        {booking.currency} {booking.advance}
                      </p>
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}

                {Number(booking.total) === 0 && !booking.cotizado ? (
                  <Grid item xs={2}></Grid>
                ) : (
                  <>
                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="primary"
                        variant="contained"
                        sx={{ height: "100%", fontWeight: "bold" }}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setOpen(true);
                          setUid(booking.uuid);
                          setBookingLater(booking);
                        }}
                      >
                        CONFIRMAR
                      </Button>

                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            ¿CONFIRMAR RESERVA?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={async (e) => {
                                const dbUrl = `bookingNow/${uid}`;
                                await update(ref(db, dbUrl), {
                                  cotizado: true,
                                });
                                setOpen(false);
                              }}
                            >
                              CONFIRMAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                              }}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>

                    <Modal
                      open={openDelete}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "bold" }}
                          textAlign={"center"}
                        >
                          Desea Eliminar esta Reserva?
                        </Typography>
                        <Stack direction="row" spacing={2}>
                          <Button
                            fullWidth
                            size="small"
                            color="primary"
                            variant="contained"
                            sx={{ fontWeight: "bold" }}
                            onClick={(e) => {
                              console.log(uid);
                              const starCountRef = ref(db, `bookingNow/${uid}`);
                              remove(starCountRef);
                              setOpenDelete(false);
                            }}
                          >
                            ELIMINAR
                          </Button>
                          <Button
                            fullWidth
                            size="small"
                            color="error"
                            variant="contained"
                            sx={{ fontWeight: "bold" }}
                            onClick={(e) => {
                              setOpenDelete(false);
                            }}
                          >
                            CANCELAR
                          </Button>
                        </Stack>
                      </Box>
                    </Modal>

                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="info"
                        variant="contained"
                        sx={{
                          height: "100%",
                          fontWeight: "bold",
                          background: "#128C7E",
                        }}
                        href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${booking.counterBooking},%0A *Cliente:* ${client.name} ${client.lastname},%0A *Origen:* ${booking.origin.name}, *Destino:* ${booking.destination.name}`}
                        target={"_blank"}
                        onClick={(e) => {
                          console.log(booking.uuid);
                          setUid(booking.uuid);
                        }}
                      >
                        Consultar&nbsp;
                        <WhatsAppIcon />
                      </Button>
                    </Grid>
                  </>
                )}

                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{ ...buttonStyle, cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log(booking);
                      setBookItem(booking);
                      setDate(dayjs(booking.date, "DD/MM/YYYY").toDate());
                      setTime(formatterTimePicker(booking.date, booking.time));

                      if (booking.booking_type == 3) {
                        setDateEnd(
                          dayjs(booking.dateEnd, "DD/MM/YYYY").toDate()
                        );
                        setTimeEnd(
                          formatterTimePicker(booking.dateEnd, booking.timeEnd)
                        );
                      }
                      setBookType(booking.booking_type);

                      if (booking.booking_type == 4) {
                        const cc = countries.filter((book) => book.id == 1);
                        setCountry(cc[0]);
                        setTour({
                          tour_id: booking.tour_id,
                          description: booking.tour_description,
                        });
                      }

                      setOrigin(booking.origin.name);
                      setDestination(booking.destination.name);
                      setCOrigin({
                        lat: booking.origin.lat,
                        lng: booking.origin.lng,
                      });
                      setCDestination({
                        lat: booking.destination.lat,
                        lng: booking.destination.lng,
                      });
                      setPersons(booking.persons);
                      setVehicle(booking.vehicle);
                      setNote(booking.note);
                      handleOpenEdit();
                    }}
                  >
                    <CreateOutlinedIcon />
                    EDITAR
                  </p>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        ))
      ) : (
        <div>{t("no_quotation")}</div>
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          height={"100%"}
          sx={styleModal}
          backgroundColor={colors.primary[400]}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                Lista
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"end"}>
              <button
                style={{
                  backgroundColor: "red",
                  borderRadius: 15,
                  width: 30,
                  height: 30,
                  border: "none",
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseModal();
                }}
              >
                X
              </button>
            </Grid>

            <Grid item xs={12}>
              {booking.length > 0 ? (
                <>
                  {dataSelected.length > 0 && (
                    <Grid item xs={12} sm={12} md={2} textAlign="end">
                      <PDFDownloadLink
                        document={
                          <Document>
                            <Page size="A4" style={styles.page}>
                              <HeaderPdf />
                              <Contact invoice={tableData} />
                              <DocumentName
                                title={"COTIZACION"}
                                from={tableData.from}
                                to={tableData.to}
                              />
                              <Rider invoice={tableData} />
                              <TableRider invoice={tableData} />
                            </Page>
                          </Document>
                        }
                        fileName={"detalles-cotizacion-taximundo.pdf"}
                      >
                        <Box width={"100%"}>
                          <Button fullWidth variant="contained" color="success">
                            <PictureAsPdfIcon /> DESCARGAR
                          </Button>
                        </Box>
                      </PDFDownloadLink>
                    </Grid>
                  )}
                  <GridDataTable
                    data={booking}
                    checkboxSelection={true}
                    columns={columns}
                    setDataSelected={setDataSelected}
                  />
                </>
              ) : (
                <>NO HAY COTIZACIONES</>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal open={openEdit} onClose={handleCloseEdit}>
        <Box sx={style}>
          <Formik>
            {({ handleChange }) => (
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      EDITAR: {typeTitle[bookType]}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <PlacesAutocomplete
                      value={origin}
                      onChange={setOrigin}
                      onSelect={(value) => {
                        handSelect(value, setOrigin, setCOrigin);
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div key={suggestions.description}>
                          <FormControl fullWidth variant="outlined">
                            <FormLabel>ORIGEN</FormLabel>
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              onChange={handleChange("weight")}
                              aria-describedby="outlined-weight-helper-text"
                              {...getInputProps({
                                placeholder: t("origin"),
                                className: "location-search-input",
                              })}
                            />
                          </FormControl>
                          <Box
                            key={suggestions.description}
                            className="autocomplete-dropdown-container suggestions"
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? suggestionActive
                                : suggestionInactive;
                              return (
                                <div
                                  key={suggestion.description}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </Box>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <PlacesAutocomplete
                      value={destination}
                      onChange={setDestination}
                      onSelect={(value) => {
                        handSelect(value, setDestination, setCDestination);
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div key={suggestions.description}>
                          <FormControl fullWidth variant="outlined">
                            <FormLabel>DESTINO</FormLabel>
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              onChange={handleChange("weight")}
                              aria-describedby="outlined-weight-helper-text"
                              {...getInputProps({
                                placeholder: t("destination"),
                                className: "location-search-input",
                              })}
                            />
                          </FormControl>
                          <Box
                            key={suggestions.description}
                            className="autocomplete-dropdown-container suggestions"
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? suggestionActive
                                : suggestionInactive;
                              return (
                                <div
                                  key={suggestion.description}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </Box>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>{t("SOLICITAR")}</InputLabel>
                      <Select
                        value={bookType}
                        id="booking"
                        name="booking"
                        label={t("SOLICITAR")}
                        onChange={(e) => setBookType(e.target.value)}
                      >
                        <MenuItem value={2}>{t("later")}(reservas)</MenuItem>
                        <MenuItem value={3}>{t("perHours")}</MenuItem>
                        <MenuItem value={4}>{t("Tour")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <MobileDatePicker
                          label={t("date").toUpperCase()}
                          inputFormat="DD/MM/YYYY"
                          name="date"
                          value={date}
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <MobileTimePicker
                          label={t("time").toUpperCase()}
                          value={time}
                          name="timeEnd"
                          onChange={(newValue) => {
                            setTime(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>

                  {bookType == 3 && (
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={2}>
                          <MobileDatePicker
                            label={t("date").toUpperCase()}
                            inputFormat="DD/MM/YYYY"
                            name="dateEnd"
                            value={dateEnd}
                            onChange={(newValue) => setDateEnd(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  )}

                  {bookType == 3 && (
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <MobileTimePicker
                            label={t("time").toUpperCase()}
                            value={timeEnd}
                            name="timeEnd"
                            onChange={(newValue) => setTimeEnd(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  )}

                  {bookType == 4 && countries && (
                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        value={country}
                        disablePortal
                        id="select-origin"
                        onChange={(e, newValue) => {
                          setCountry(newValue);
                        }}
                        options={countries}
                        getOptionLabel={(option) => `${option.name}`}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("PAIS")} />
                        )}
                      />
                    </Grid>
                  )}

                  {tours.length > 0 && (
                    <Grid item xs={12}>
                      <Autocomplete
                        value={tour}
                        disablePortal
                        id="select-tour"
                        onChange={(e, newValue) => {
                          console.log(newValue);
                          setTour(newValue);
                        }}
                        options={tours}
                        getOptionLabel={(option) => `${option.description}`}
                        isOptionEqualToValue={(option, value) =>
                          option.description === value.description
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("TOUR")} />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>{t("vehicle").toUpperCase()}</InputLabel>
                      <Select
                        value={vehicle}
                        id="vehicle"
                        name="vehicle"
                        label={t("vehicle").toUpperCase()}
                        onChange={(e) => {
                          setVehicle(e.target.value);
                        }}
                      >
                        {modelApi.map((model, index) => (
                          <MenuItem key={index} value={model.id}>
                            {t(model.name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="PERSONAS"
                      fullWidth
                      type="number"
                      variant="outlined"
                      value={persons}
                      onChange={(e) => setPersons(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="outlined-basic"
                      label="NOTAS"
                      fullWidth
                      variant="outlined"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <Button
                      fullWidth
                      type="submit"
                      color="error"
                      variant="contained"
                      size="large"
                      sx={{ height: "100%" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseEdit();
                      }}
                    >
                      CERRAR
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      size="large"
                      sx={{ height: "100%" }}
                      onClick={(e) => updateBookingLater(e, bookItem)}
                    >
                      ACTUALIZAR
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>

      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </>
  );
};

export default ListQuote;
