export const MODE = "prod";
export const URL_LOGIN = "/api/v1/signin";
export const URL_REGISTER = "/api/v1/register";
export const URL_FORGOT_PASSWORD = "/api/v1/forgot-password";
export const URL_RE_SEND_EMAIL = "/api/v1/re-send-email";
export const URL_CONFIRM_EMAIL = "/api/v1/confirm-email";

export const URL_MODEL = "/api/v1/resource/models";
export const URL_GENRES = "/api/v1/resource/genres";
export const URL_RESET = "/api/v1/reset-password";

export const URL_DRIVER = "/api/v1/driver";
export const URL_CLIENT = "/api/v1/client";
export const URL_TRAVELLER = "/api/v1/traveller";
export const URL_VEHICLE = "/api/v1/vehicle";
export const URL_ADDRESS_LATLNG = "/api/v1/resource/url";

export const URL_SUNAT_DNI = "/api/v1/resource/dni";

export const URL_BOOKING = "/api/v1/booking-now";
