import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BookingNow from "./booking/BookingNow";
import {
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getModels } from "../../../api/model";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width={"100%"}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BookingTab = () => {
  const [value, setValue] = useState(0);
  const [model, setModel] = useState([]);
  const theme = createTheme();
  const { t } = useTranslation();

  const handleChange = (event, newValue) => setValue(newValue);
  //Lista Modelos de Vehiculos
  useEffect(() => {
    const models = async () => {
      try {
        const response = await getModels();
        const data = response.data.filter((model) => model.id);
        setModel(data);
      } catch (error) {
        console.log(error);
      }
    };
    models();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Booking-Tabs"
              centered
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                label={t("bookings")}
                {...a11yProps(0)}
                sx={{ color: "#000000", fontSize: 18 }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} >
            <BookingNow model={model} />
          </TabPanel>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default BookingTab;
