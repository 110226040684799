import { get, ref, remove } from "firebase/database";
import { db } from "../../../../../firebase";

export const DeleteAllCounterOffers = async (idRider, idBooking) => {
  try {
    console.log(idRider, idBooking);
    const refPath = `CounterOffer/Normal`;
    const refDB = ref(db, refPath);
    const snapshot = await get(refDB);
    const resp = snapshot.val();

    if (resp != null) {
      Object.values(resp).map((offer) => {
        if (
          Number(offer.idRider) === Number(idRider) &&
          offer.idBooking === idBooking
        ) {
          const idCounterOffer = offer.idCounterOffer;
          const delPath = `CounterOffer/Normal/${idCounterOffer}`;
          const refOffer = ref(db, delPath);
          remove(refOffer);
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};
