import React, { useEffect, useState } from "react";
import { db } from "../../../../firebase";
import { ref, onValue, update } from "firebase/database";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Modal,
  Box,
  Stack,
  useTheme,
  FormControl,
  OutlinedInput,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Autocomplete,
} from "@mui/material";

import Header from "../../../../components/header/Header";
import {
  decodeTokenRefresh,
  getClientToken,
  getTokenRefresh,
  decodeClientToken,
} from "../../../../helpers/token/refreshToken";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../../theme";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import HeaderPdf from "./pdf/elements/Header";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
} from "@react-pdf/renderer";
import GridDataTable from "./table/GridDataTable";
import DocumentName from "./pdf/elements/DocumentName";
import Rider from "./pdf/elements/Rider";
import TableRider from "./pdf/elements/TableRider";
import Contact from "./pdf/elements/Contact";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";
import { getAdvanceCashOrder } from "../../../../api/booking/advance";
import { GenerateOpenpayUseCase } from "../../../../api/openpay/GenerateOpenpayUseCase";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { getModels } from "../../../../api/model";

import {
  buttonStyle,
  suggestionActive,
  suggestionInactive,
} from "./style/PendingLater.style";
import "./style/booking.css";
import { Formik } from "formik";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  dateFormat,
  formatDateTime,
  timeFormat,
} from "../../../../helpers/date/date";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { getListCountryUseCase } from "../booking/usecases/country/GetListCountryUseCase";
import { getListTourByCountry } from "../booking/data/repository/tourRepo";

const HistoryBookingLater = ({ client }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [booking, setBooking] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const model = ["", "Sedan", "SUV", "Minivan"];
  const currency = ["", "PEN", "USD"];
  const curren = {
    1: "PEN",
    2: "USD",
  };
  const typeTitle = {
    2: "TRASLADO",
    3: "TRANSLADO POR HORAS",
    4: "TOUR",
  };
  const [open, setOpen] = React.useState(false);
  const [uid, setUid] = useState("");
  const [bookingLater, setBookingLater] = useState([]);
  const handleClose = () => setOpen(false);

  const token = getTokenRefresh();
  const id = decodeTokenRefresh(token);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    const starCountRef = ref(db, `bookingNow/`);
    onValue(starCountRef, (snapshot) => {
      let data = snapshot.val();
      if (data === null) return setBooking([]);

      setBooking([]);
      Object.values(data).map((book) => {
        if (book.client === id && book.booking_type != 1 && book.cotizado) {
          book.currency = curren[book.currency];
          book.fullorigin = book.origin.name;
          book.fulldestination = book.destination.name;
          setBooking((oldBooking) => [...oldBooking, book]);
        }
      });
    });
  }, []);

  //traer todos los pagos que tiene en mysql
  useEffect(() => {
    setListAdvance([]);
    if (booking.length > 0) {
      booking.map(async (item, idx) => {
        const orderNumber = item.counterBooking;
        const resp = await getAdvanceCashOrder(orderNumber);
        const data = resp.data.resp;
        if (data.length > 0) {
          const list = {
            orderNumber,
            data,
          };
          console.log(list);
          setListAdvance((oldData) => [...oldData, list]);
        }
      });
    }
  }, [booking]);
  //
  const [listAdvance, setListAdvance] = useState([]);

  const dateOpenpayFormat = () => {
    var fecha = new Date();

    var año = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agregar un cero inicial si es necesario
    var dia = ("0" + fecha.getDate()).slice(-2); // Agregar un cero inicial si es necesario
    var hora = ("0" + fecha.getHours()).slice(-2); // Agregar un cero inicial si es necesario
    var minuto = ("0" + fecha.getMinutes()).slice(-2); // Agregar un cero inicial si es necesario
    var segundo = ("0" + fecha.getSeconds()).slice(-2); // Agregar un cero inicial si es necesario

    var formatoFecha =
      año + "-" + mes + "-" + dia + "T" + hora + ":" + minuto + ":" + segundo;

    return formatoFecha;
  };

  const generateOpenPayForm = async (booking) => {
    try {
      setIsLoading(true);
      const baseUrl = "https://booking.taximundo.com/client";
      // const baseUrl = "http://localhost:3001/client";
      const redirectUrl = `${baseUrl}/transfer/${booking.currency}`;

      const dataToken = getClientToken();
      const { name, lastname, phone, email } = decodeClientToken(dataToken);
      const orderID = `${booking.counterBooking}-${Date.now()}`;
      const bookingOpenPay = {
        method: "card",
        amount: Number(booking.advance),
        currency: booking.currency,
        description: `${booking.counterBooking}. PAGO POR SERVICIO DE TRANSPORTE`,
        order_id: orderID,
        confirm: "false",
        send_email: "false",
        redirect_url: redirectUrl,
        due_date: dateOpenpayFormat(), //"2023-12-09T19:22:00",
        customer: {
          name: name,
          last_name: lastname,
          phone_number: phone,
          email: email,
        },
      };

      const response = await GenerateOpenpayUseCase(bookingOpenPay);

      if (response.message === "success") {
        const paymentUrl = response.data.payment_method.url;
        const transactionId = response.data.id;
        const orderId = response.data.order_id;
        window.open(paymentUrl, "_blank");
        const openpayData = {
          transaction_id: transactionId,
          order_id: orderId,
          createBy: "B",
        };
        const fbUrl = `bookingNow/${booking.uuid}/openpay`;
        await update(ref(db, fbUrl), openpayData);
      } else {
        showSnackbar("ERROR: OPENPAY", "error");
      }
      setIsLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setOpen(false);
    }
  };

  const [openPdf, setOpenPdf] = useState(false);
  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenPdf(true);
  };
  const handleCloseModal = () => setOpenPdf(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #000",
    boxShadow: 20,
    width: "100%",
    p: 2,
    overflow: "scroll",
  };
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 35,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  const [dataSelected, setDataSelected] = useState([]);

  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    let total = 0;
    let discount = 0;
    let advance = 0;

    Object.values(dataSelected).map((book) => {
      console.log(book);
      total += Number(book.total);
      discount += Number(book.discount);
      advance += Number(book.advance ? book.advance : 0);
    });

    setTableData({});
    setTableData({
      id: "5df3180a09ea16dc4b95f910",
      invoice_no: "201906-28",
      company: "TAXIMUNDO",
      email: "info@taximundo.com",
      phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
      address: "Servicios Generales Trans Mundo E.I.R.L.",
      items: dataSelected,
      igv: 0,
      total: total,
      discount,
      advance,
      peaje: 0,
      subTotal: 0,
      from: 0,
      to: 0,
    });
  }, [dataSelected]);

  const columns = [
    {
      headerName: "Pedido",
      field: "counterBooking",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
      renderCell: ({ row: { counterBooking } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              color: "white",
              background: "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {counterBooking}
          </div>
        );
      },
    },
    {
      headerName: "NOMBRE",
      field: "clientName",
      width: 130,
    },
    {
      headerName: "FECHA",
      field: "date",
      width: 80,
    },
    {
      headerName: "ORIGEN",
      field: "fullorigin",
      width: 250,
    },
    {
      headerName: "DESTINO",
      field: "fulldestination",
      width: 250,
    },
    {
      headerName: "TOTAL",
      field: "total",
      width: 100,
    },
  ];

  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const handleCloseSnk = () => setStateSnk(false);

  const showSnackbar = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [bookItem, setBookItem] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [cOrigin, setCOrigin] = useState("");
  const [cDestination, setCDestination] = useState("");

  const [bookType, setBookType] = useState(1);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [timeEnd, setTimeEnd] = useState();
  const [persons, setPersons] = useState(0);
  const [vehicle, setVehicle] = useState(1);
  const [note, setNote] = useState("");
  const [modelApi, setModelApi] = useState([]);

  useEffect(() => {
    const models = async () => {
      try {
        const response = await getModels();
        const data = response.data.filter((model) => model.id);
        setModelApi(data);
      } catch (error) {
        console.log(error);
      }
    };
    models();
  }, []);

  const formatterTimePicker = (date, time) => {
    const [day, month, year] = date.split("/");
    const [hourMin, amPm] = time.split(" ");
    const [hour, minute] = hourMin.split(":");
    const realHour =
      amPm == "AM" ? hour : hour == 12 ? hour : parseInt(hour) + 12;
    return new Date(year, month - 1, day, realHour, minute);
  };

  const handSelect = async (value, setAddress, setCoordinate) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddress(value);
    setCoordinate(ll);
  };

  const checkTime = () => {
    const today = new Date();
    const { day, month, year, hour, minute } = formatDateTime(
      date.$d ?? date,
      time.$d ?? time
    );

    const dateOrder = new Date(year, month, day, hour, minute, 0);
    const diffInMillisec = dateOrder.getTime() - today.getTime();
    const diffInMin = diffInMillisec / (1000 * 60);

    if (diffInMin <= 1440) {
      showSnackbar("TODA RESERVA DEBE SER MAYOR O IGUAL A 1 DIA", "error");
      return;
    }

    return true;
  };
  const updateBookingLater = async (e, item) => {
    e.preventDefault();
    try {
      if (checkTime()) {
        const refPath = `bookingNow/${item.uuid}`;
        const refDB = ref(db, refPath);

        let dateend = "";
        let timeend = "";
        if (item.booking_type == 3) {
          dateend = dateEnd.$d ? dateFormat(dateEnd.$d) : dateFormat(dateEnd);
          timeend = timeEnd.$d ? timeFormat(timeEnd.$d) : timeFormat(timeEnd);
        }

        const data = {
          origin: {
            lat: cOrigin.lat,
            lng: cOrigin.lng,
            name: origin,
          },
          destination: {
            lat: cDestination.lat,
            lng: cDestination.lng,
            name: destination,
          },
          date: date.$d ? dateFormat(date.$d) : dateFormat(date),
          time: time.$d ? timeFormat(time.$d) : timeFormat(time),
          dateEnd: dateend,
          timeEnd: timeend,
          persons: Number(persons) ?? 1,
          vehicle: Number(vehicle) ?? 1,
          note: note.toUpperCase() ?? "",
        };
        console.log(data);
        await update(refDB, data);
        handleCloseEdit();
        showSnackbar("DATOS ACTUALIZADOS");
      }
    } catch (error) {
      console.log(error);
      showSnackbar("SELECCIONE LA FECHA Y HORA", "error");
    }
  };

  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountrys = async () => {
      try {
        const response = await getListCountryUseCase();
        setCountries([]);
        setCountries(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCountrys();
  }, []);

  const [tour, setTour] = useState(null);
  const [tours, setTours] = useState([]);

  useEffect(() => {
    if (country) {
      const getListTour = async () => {
        try {
          const response = await getListTourByCountry(country.id);
          setTours([]);
          setTours(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      getListTour();
    }
  }, [country]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={""} subtitle={t("bookings")} />
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleOpenModal}
            sx={{ marginRight: 1 }}
          >
            <CloudDownloadOutlinedIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            <FiberNewOutlinedIcon /> {t("create new order")}
          </Button>
        </Box>
      </Box>

      {booking.length > 0 ? (
        booking.map((booking, index) => (
          <Card key={index} sx={{ border: 1, margin: "1px -3px 2px -3px" }}>
            <CardContent>
              <Grid container>
                <Grid item xs={4} sm={6}>
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    TM-{booking.counterBooking}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={booking.booking_type === 3 ? 3 : 6}
                  textAlign={"end"}
                >
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    {booking.date} - {booking.time}
                  </Typography>
                </Grid>
                {booking.booking_type === 3 && (
                  <Grid item xs={12} sm={3} textAlign={"end"}>
                    <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                      {booking.dateEnd} - {booking.timeEnd}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h7"
                    color={"primary"}
                    sx={{ fontWeight: "bold" }}
                  >
                    A Origen: {booking.origin.name}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h7"
                    color={"error"}
                    sx={{ fontWeight: "bold" }}
                  >
                    B Destino: {booking.destination.name}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Vehicle: {model[booking.vehicle]}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Persons: {booking.persons}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Nota: {booking.note}
                  </Typography>
                </Grid>

                {listAdvance.length > 0 &&
                  listAdvance.map((item, index) => {
                    const check =
                      Number(booking.counterBooking) ==
                      Number(item.orderNumber);

                    if (check) {
                      return (
                        <div key={index}>
                          <div style={{ width: "100%", fontWeight: "bold" }}>
                            Adelanto:
                          </div>
                          {item.data.map((adv, idx) => {
                            return (
                              <div
                                key={idx}
                                style={{ width: "100%", fontWeight: "bold" }}
                              >
                                {idx + 1}.- {adv.currency} {adv.amount}{" "}
                                {adv.status} {adv.operation_date}
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                  })}
              </Grid>
            </CardContent>
            <CardActions sx={{ mt: -2 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} md={2}>
                  <div style={buttonStyle}>
                    <DriveEtaOutlinedIcon />
                    &nbsp;
                    {booking.openpay ? (
                      booking.openpay?.status === "pending" ? (
                        "PENDIENTE"
                      ) : (
                        <div style={{ display: "block" }}>
                          <p style={{ fontSize: 11, color: "green" }}>PAGADO</p>
                          <p style={{ fontSize: 11 }}>
                            {booking.currency} {booking.advance}
                          </p>
                        </div>
                      )
                    ) : (
                      "PENDIENTE"
                    )}
                  </div>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                  <div style={{ ...buttonStyle, display: "block" }}>
                    <p style={{ fontSize: 11, color: "green" }}>Precio</p>
                    <p>
                      {booking.currency} {booking.total}
                    </p>
                  </div>
                </Grid>

                {booking.discount && booking.discount > 0 ? (
                  <Grid item xs={6} sm={3} md={2}>
                    <div
                      style={{
                        fontWeight: "bold",
                        background: "white",
                        borderRadius: 3,
                        borderStyle: "solid",
                        borderColor: "black",
                        borderWidth: 1,
                        textAlign: "center",
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        width: "100%",
                        height: "50px",
                        fontSize: 15,
                      }}
                    >
                      <p style={{ fontSize: 11, color: "green" }}>Descuento</p>
                      <p>
                        {booking.currency} {booking.discount}
                      </p>
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}

                {booking.advance && booking.advance > 0 ? (
                  <Grid item xs={6} sm={3} md={2}>
                    <div
                      style={{
                        fontWeight: "bold",
                        background: "white",
                        borderRadius: 3,
                        borderStyle: "solid",
                        borderColor: "black",
                        borderWidth: 1,
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        width: "100%",
                        height: "50px",
                        fontSize: 15,
                        display: "block",
                      }}
                    >
                      <p style={{ fontSize: 11, color: "green" }}>Adelanto</p>
                      <p>
                        {booking.currency} {booking.advance}
                      </p>
                    </div>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={6} sm={3} md={2}>
                  <Button
                    fullWidth
                    size="small"
                    color="info"
                    variant="contained"
                    sx={{
                      height: "50px",
                      fontWeight: "bold",
                      background: "#128C7E",
                    }}
                    href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${booking.counterBooking},%0A *Cliente:* ${booking.clientName},%0A *Origen:* ${booking.origin.name}, *Destino:* ${booking.destination.name} `}
                    target={"_blank"}
                    onClick={(e) => {
                      console.log(booking.uuid);
                      setUid(booking.uuid);
                    }}
                  >
                    Consultar&nbsp;
                    <WhatsAppIcon />
                  </Button>
                </Grid>
                {booking.advance > 0 &&
                  booking.openpay &&
                  booking.openpay.btnPay && (
                    <Grid item xs={6} sm={3} md={2}>
                      <Button
                        fullWidth
                        size="small"
                        color="primary"
                        variant="contained"
                        sx={{ height: "50px", fontWeight: "bold" }}
                        onClick={(e) => {
                          setOpen(true);
                          setUid(booking.uuid);
                          setBookingLater(booking);
                        }}
                      >
                        PAGAR&nbsp;
                        <ContactlessOutlinedIcon />
                      </Button>

                      {/* CREAR LA FUNCIONALIDAD PARA PAGAR */}
                      <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                            textAlign={"center"}
                          >
                            PAGAR PEDIDO?
                          </Typography>
                          <Stack direction="row" spacing={2}>
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              disabled={isLoading}
                              onClick={(e) => {
                                generateOpenPayForm(bookingLater);
                              }}
                            >
                              CONFIRMAR
                            </Button>
                            <Button
                              fullWidth
                              size="small"
                              color="error"
                              variant="contained"
                              sx={{ fontWeight: "bold" }}
                              onClick={(e) => {
                                setOpen(false);
                              }}
                            >
                              CANCELAR
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Grid>
                  )}

                <Grid item xs={6} sm={3} md={2}>
                  <p
                    style={{ ...buttonStyle, cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log(booking);
                      setBookItem(booking);
                      setDate(dayjs(booking.date, "DD/MM/YYYY").toDate());
                      setTime(formatterTimePicker(booking.date, booking.time));
                      if (booking.booking_type == 3) {
                        setDateEnd(
                          dayjs(booking.dateEnd, "DD/MM/YYYY").toDate()
                        );
                        setTimeEnd(
                          formatterTimePicker(booking.dateEnd, booking.timeEnd)
                        );
                      }

                      setBookType(booking.booking_type);

                      if (booking.booking_type == 4) {
                        const cc = countries.filter((book) => book.id == 1);
                        setCountry(cc[0]);
                        setTour({
                          tour_id: 0,
                          description: booking.tour_description,
                        });
                      }
                      setOrigin(booking.origin.name);
                      setDestination(booking.destination.name);
                      setCOrigin({
                        lat: booking.origin.lat,
                        lng: booking.origin.lng,
                      });
                      setCDestination({
                        lat: booking.destination.lat,
                        lng: booking.destination.lng,
                      });
                      setPersons(booking.persons);
                      setVehicle(booking.vehicle);
                      setNote(booking.note);
                      handleOpenEdit();
                    }}
                  >
                    <CreateOutlinedIcon />
                    EDITAR
                  </p>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        ))
      ) : (
        <div>{t("no_bookings")}</div>
      )}

      <Modal open={openEdit} onClose={handleCloseEdit}>
        <Box sx={style}>
          <Formik>
            {({ handleChange }) => (
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                      }}
                    >
                      EDITAR: {typeTitle[bookType]}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <PlacesAutocomplete
                      value={origin}
                      onChange={setOrigin}
                      onSelect={(value) => {
                        handSelect(value, setOrigin, setCOrigin);
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div key={suggestions.description}>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              onChange={handleChange("weight")}
                              aria-describedby="outlined-weight-helper-text"
                              {...getInputProps({
                                placeholder: t("origin"),
                                className: "location-search-input",
                              })}
                            />
                          </FormControl>
                          <Box
                            key={suggestions.description}
                            className="autocomplete-dropdown-container suggestions"
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? suggestionActive
                                : suggestionInactive;
                              return (
                                <div
                                  key={suggestion.description}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </Box>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <PlacesAutocomplete
                      value={destination}
                      onChange={setDestination}
                      onSelect={(value) => {
                        handSelect(value, setDestination, setCDestination);
                      }}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div key={suggestions.description}>
                          <FormControl fullWidth variant="outlined">
                            <OutlinedInput
                              id="outlined-adornment-weight"
                              onChange={handleChange("weight")}
                              aria-describedby="outlined-weight-helper-text"
                              {...getInputProps({
                                placeholder: t("destination"),
                                className: "location-search-input",
                              })}
                            />
                          </FormControl>
                          <Box
                            key={suggestions.description}
                            className="autocomplete-dropdown-container suggestions"
                          >
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? suggestionActive
                                : suggestionInactive;
                              return (
                                <div
                                  key={suggestion.description}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </Box>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel>{t("SOLICITAR")}</InputLabel>
                      <Select
                        value={bookType}
                        id="booking"
                        name="booking"
                        label={t("SOLICITAR")}
                        onChange={(e) => setBookType(e.target.value)}
                      >
                        <MenuItem value={2}>{t("later")}(reservas)</MenuItem>
                        <MenuItem value={3}>{t("perHours")}</MenuItem>
                        <MenuItem value={4}>{t("Tour")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={2}>
                        <MobileDatePicker
                          label={t("date")}
                          inputFormat="DD/MM/YYYY"
                          name="date"
                          value={date}
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <MobileTimePicker
                          label={t("time")}
                          value={time}
                          name="timeEnd"
                          onChange={(newValue) => {
                            setTime(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>

                  {bookType == 3 && (
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={2}>
                          <MobileDatePicker
                            label={t("date")}
                            inputFormat="DD/MM/YYYY"
                            name="dateEnd"
                            value={dateEnd}
                            onChange={(newValue) => setDateEnd(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  )}

                  {bookType == 3 && (
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <MobileTimePicker
                            label={t("time")}
                            value={timeEnd}
                            name="timeEnd"
                            onChange={(newValue) => setTimeEnd(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  )}

                  {bookType == 4 && countries && (
                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        value={country}
                        disablePortal
                        id="select-origin"
                        onChange={(e, newValue) => {
                          setCountry(newValue);
                        }}
                        options={countries}
                        getOptionLabel={(option) => `${option.name}`}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("PAIS")} />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>{t("vehicle")}</InputLabel>
                      <Select
                        value={vehicle}
                        id="vehicle"
                        name="vehicle"
                        label={t("vehicle")}
                        onChange={(e) => {
                          setVehicle(e.target.value);
                        }}
                      >
                        {modelApi.map((model, index) => (
                          <MenuItem key={index} value={model.id}>
                            {t(model.name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="outlined-basic"
                      label="PERSONAS"
                      fullWidth
                      type="number"
                      variant="outlined"
                      value={persons}
                      onChange={(e) => setPersons(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="outlined-basic"
                      label="NOTAS"
                      fullWidth
                      variant="outlined"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <Button
                      fullWidth
                      type="submit"
                      color="error"
                      variant="contained"
                      size="large"
                      sx={{ height: "100%" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleCloseEdit();
                      }}
                    >
                      CERRAR
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      size="large"
                      sx={{ height: "100%" }}
                      onClick={(e) => updateBookingLater(e, bookItem)}
                    >
                      ACTUALIZAR
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>

      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={4000}
        handleClose={handleCloseSnk}
        severity={severity}
        messageSnk={messageSnk}
      />

      {/* MODAL PDF SELECT */}
      <Modal open={openPdf} onClose={handleCloseModal}>
        <Box
          height={"100%"}
          sx={styleModal}
          backgroundColor={colors.primary[400]}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                Lista
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign={"end"}>
              <button
                style={{
                  backgroundColor: "red",
                  borderRadius: 15,
                  width: 30,
                  height: 30,
                  border: "none",
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseModal();
                }}
              >
                X
              </button>
            </Grid>

            {dataSelected.length > 0 && (
              <Grid item xs={12} sm={12} md={2} textAlign="end">
                <PDFDownloadLink
                  document={
                    <Document>
                      <Page size="A4" style={styles.page}>
                        <HeaderPdf />
                        {/* <Title title="Taxi Mundo PERU" /> */}
                        <Contact invoice={tableData} />
                        <DocumentName
                          title={"COTIZACION"}
                          from={tableData.from}
                          to={tableData.to}
                        />
                        <Rider invoice={tableData} />
                        <TableRider invoice={tableData} />
                      </Page>
                    </Document>
                  }
                  fileName={"detalles-reservas-taximundo.pdf"}
                >
                  <Box width={"100%"}>
                    <Button fullWidth variant="contained" color="success">
                      <PictureAsPdfIcon /> DESCARGAR
                    </Button>
                  </Box>
                </PDFDownloadLink>
              </Grid>
            )}
            <Grid item xs={12}>
              {booking.length > 0 ? (
                <GridDataTable
                  data={booking}
                  checkboxSelection={true}
                  columns={columns}
                  setDataSelected={setDataSelected}
                />
              ) : (
                <>LISTA DE RESERVAS VACIA</>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default HistoryBookingLater;
