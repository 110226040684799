export const buttonStyle = {
  fontWeight: "bold",
  background: "white",
  borderRadius: 3,
  borderStyle: "solid",
  borderColor: "black",
  borderWidth: 1,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "black",
  width: "100%",
  maxHeight: "100%",
  minHeight: "45px",
  fontSize: 15,
};

export const suggestionActive = {
  backgroundColor: "#fafafa",
  cursor: "pointer",
};

export const suggestionInactive = {
  backgroundColor: "#d1d5cf",
  cursor: "pointer",
};
