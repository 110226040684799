import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
  },

  lineContainer: {
    flexDirection: "row",
    marginTop: 1,
  },
  enterprise: {
    width: "80%",
    fontSize: 12,
  },
  ruc: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
  },
});

const Rider = ({ invoice }) => (
  <View>
    <Text>
      <Text style={{ fontFamily: "Helvetica-Bold" }}>Responsable: </Text>
      {invoice.items[0]?.clientName}
    </Text>
  </View>
);

export default Rider;
