import { ref, remove } from "firebase/database";
import { db } from "../../../../../firebase";

export const DeleteBooking = async (idBooking) => {
  try {
    const refPath = `bookingNow/${idBooking}`;
    const refDB = ref(db, refPath);
    remove(refDB);
  } catch (error) {
    console.log(error);
  }
};
