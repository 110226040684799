import { isExpired, decodeToken } from "react-jwt";

export const setToken = (name, token) => {
  window.localStorage.setItem(name, JSON.stringify(token));
};

export const getToken = (name) => {
  const tokenJson = window.localStorage.getItem(name);
  const token = JSON.parse(tokenJson);
  return token;
};

export const getTokenRefresh = () => {
  const tokenJson = window.localStorage.getItem("ctknre");
  const token = JSON.parse(tokenJson);
  return token;
};

export const getClientToken = () => {
  const tokenJson = window.localStorage.getItem("cdttkn");
  const token = JSON.parse(tokenJson);
  return token;
};

export const deleteToken = (nameToken) => {
  window.localStorage.removeItem(nameToken);
};

export const verifyExpireTokenRefresh = () => {
  const token = getTokenRefresh();

  if (!token) return true;

  const isTokenExpired = isExpired(token);
  return isTokenExpired;
};

export const decodeTokenRefresh = (token) => {
  const { uid } = decodeToken(token);
  return uid;
};

export const decodeClientToken = (token) => {
  const { name, lastname, email, phone } = decodeToken(token);
  return { name, lastname, email, phone };
};
