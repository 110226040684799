import React from "react";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBar = ({
  stateSnk,
  vertical,
  horizontal,
  duration = 4000,
  handleClose,
  severity,
  messageSnk,
}) => {
  return (
    <Snackbar
      autoHideDuration={duration}
      anchorOrigin={{ vertical, horizontal }}
      open={stateSnk}
      onClose={handleClose}
      key={vertical + horizontal}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {messageSnk}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
