import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { dateFormat, timeFormat } from "../../../../../../helpers/date/date";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 1,
    display: "flex",
  },
  document: {
    color: "#4B44BE",
    fontSize: 18,
    width: "50%",
    marginLeft: 80,
    textTransform: "uppercase",
    fontFamily: "Helvetica-Bold",
  },
  numb: {
    color: "#4B44BE",
    fontSize: 11,
    marginTop: 3,
    textTransform: "uppercase",
    fontFamily: "Helvetica-Bold",
  },
});

const DocumentName = ({ title, from, to }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.document}>{title}</Text>
    <Text style={styles.numb}>
      Fecha: {dateFormat(new Date(Date.now()))} Hora: {timeFormat(new Date(Date.now()))}
    </Text>
  </View>
);

export default DocumentName;
