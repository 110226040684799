import { getFrequentRider } from "../data/repository/rider.repository";

export const GetFrequentClient = async (id) => {
  try {
    const response = await getFrequentRider(id);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
