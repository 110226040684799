export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "90vh",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 1,
};
