import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Button,
  TextField,
  Container,
  CssBaseline,
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Formik } from "formik";
import * as yup from "yup";

import { registerClient } from "../../../api/axios";
import Header from "../../../components/header/Header";
import { countries } from "../../../helpers/countrys";
import TopMenu from "../../../components/topbar/TopMenu";
import { Copyright } from "../../../components/copyright/Copyright";
import { registerSchema, registerValues } from "./RegisterValues";
import SnackBar from "../../../components/snackbar/SnackBar";

const Register = () => {
  const theme = createTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [codeCountry, setCodeCountry] = useState(countries[172].phone);
  const [country, setCountry] = useState(countries[172].label);

  const checkoutSchema = yup.object().shape(registerSchema(yup));

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      if (values.genre !== "") {
        if (values.password === values.confirmPwd) {
          const phoneNumber = values.phone.replace(/\s+/g, "");

          console.log(`${codeCountry} ${phoneNumber}`);
          await registerClient(
            values.email,
            values.password,
            values.name.toUpperCase(),
            values.lastName.toUpperCase(),
            `${codeCountry} ${phoneNumber}`,
            values.address.toUpperCase(),
            country.toUpperCase(),
            values.genre
          );

          snackBarDialog(
            "CUENTA CREADA, REVISE SU CORREO PARA VALIDAR SU CUENTA"
          );
          setLoading(false);
          setTimeout(() => {
            navigate("/login");
          }, 5000);
        } else {
          snackBarDialog("LOS CAMPOS DE password deben ser IGUALES", "error");
          setLoading(false);
        }
      } else {
        snackBarDialog("SELECCIONE EL CAMPO DE GENERO", "error");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.error === "EMAIL_IS_ALREADY_REGISTERED") {
        snackBarDialog("ERROR, EMAIL YA ESTA REGISTRADO", "error");
      } else {
        snackBarDialog("ERROR, NO SE LOGRO CREAR SU CUENTA", "error");
      }
      setLoading(false);
    }
  };

  const formatPhoneNumber = (input) => {
    const phoneNumber = input.replace(/\s+/g, "");
    let formattedPhoneNumber = "";

    for (let i = 0; i < phoneNumber.length; i++) {
      if (i > 0 && i % 3 === 0) {
        formattedPhoneNumber += " ";
      }
      formattedPhoneNumber += phoneNumber[i];
    }

    return formattedPhoneNumber;
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPwd = () => setShowConfirmPwd((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <>
      <TopMenu />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box>
            <Header title={t("register")} subtitle={t("registerDescription")} />
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={registerValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box gap="20px" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.name}
                          fullWidth
                          type="text"
                          label={t("name").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="name"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={!!touched.name && !!errors.name}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.lastName}
                          fullWidth
                          type="text"
                          label={t("lastname").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="lastName"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={!!touched.lastName && !!errors.lastName}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.email}
                          fullWidth
                          type="text"
                          label={"Email".toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="email"
                          error={!!touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>{t("genre").toUpperCase()}</InputLabel>
                          <Select
                            value={values.genre}
                            id="genre"
                            name="genre"
                            label={t("genre").toUpperCase()}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>{t("FEMALE")}</MenuItem>
                            <MenuItem value={2}>{t("MALE")}</MenuItem>
                            <MenuItem value={3}>{t("OTHERS")}</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>{t("password").toUpperCase()}</InputLabel>
                          <OutlinedInput
                            id="outlined-password"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label={t("password").toUpperCase()}
                            error={!!touched.password && !!errors.password}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>
                            {t("Confirm Password").toUpperCase()}
                          </InputLabel>
                          <OutlinedInput
                            id="confirm-password"
                            type={showConfirmPwd ? "text" : "password"}
                            value={values.confirmPwd}
                            name="confirmPwd"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPwd}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPwd ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label={t("Confirm Password").toUpperCase()}
                            error={!!touched.confirmPwd && !!errors.confirmPwd}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          id="country-select"
                          options={countries}
                          defaultValue={countries[172]}
                          autoHighlight
                          onChange={(event, newValue) => {
                            if (newValue) {
                              const label = newValue.label; // Aquí accedemos directamente al valor seleccionado
                              const code = newValue.phone;
                              setCodeCountry(code);
                              setCountry(label);
                            }
                          }}
                          getOptionLabel={(option) =>
                            `(${option.phone}) ${option.label} `
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code}) {option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.phone}
                          fullWidth
                          type="text"
                          label={t("phone").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            const { value } = event.target;
                            const formattedPhoneNumber =
                              formatPhoneNumber(value);
                            handleChange({
                              target: {
                                name: "phone",
                                value: formattedPhoneNumber,
                              },
                            });
                          }}
                          name="phone"
                          inputProps={{ maxLength: 18 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  {codeCountry}
                                </span>
                              </InputAdornment>
                            ),
                          }}
                          error={!!touched.phone && !!errors.phone}
                          helperText={touched.phone && errors.phone}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          type="text"
                          label={t("address").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address}
                          name="address"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={!!touched.address && !!errors.address}
                          helperText={touched.address && errors.address}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box display="flex" justifyContent="center" mt="20px">
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={loading}
                    >
                      {t("createAccount")}
                    </Button>
                  </Box>
                  <SnackBar
                    stateSnk={stateSnk}
                    vertical={vertical}
                    horizontal={horizontal}
                    duration={5000}
                    handleClose={handleSnkClose}
                    severity={severity}
                    messageSnk={messageSnk}
                  />
                </form>
              )}
            </Formik>
          </Box>
          <Copyright sx={{ mt: 4, mb: 2 }} />
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Register;
