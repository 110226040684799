import { URL_CLIENT } from "../helpers/endpoint";
import { baseBackend } from "./base/baseUrl";

export const getClient = async (token) => {
  return await baseBackend.get(URL_CLIENT, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getClientId = async (token, id) => {
  return await baseBackend.get(`${URL_CLIENT}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateClient = async (token, client) => {
  return await baseBackend.put(URL_CLIENT, JSON.stringify({ client }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
