import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";

import { login, reSendConfirmEmail } from "../../../api/axios";
import logo from "../../../assets/logo.png";
import { Copyright } from "../../../components/copyright/Copyright";
import Notification from "../../../components/notificarions/AlertNotification";
import { setToken } from "../../../helpers/token/refreshToken";
import SnackBar from "../../../components/snackbar/SnackBar";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { LoginSocialFacebook } from "reactjs-social-login";
import { useGoogleLogin } from "@react-oauth/google";
import { loginWithGoogleUseCase } from "./domain/loginWithGoogleUseCase";
import { authRiderGoogleUseCase } from "./domain/authRiderGoogleUseCase";
import { authRiderFacebookUseCase } from "./domain/authRiderFacebookUseCase";
import { setImageProfile } from "../../../helpers/token/imageStore";

const theme = createTheme();

export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const goHome = () => navigate("/");

  const emailRequire = t("emailRequire");
  const emailInvalid = t("emailInvalid");
  const passwordRequire = t("passwordRequire");
  const passwordLong = t("passwordLong");

  const checkoutSchema = yup.object().shape({
    email: yup.string().email(emailInvalid).required(emailRequire),
    password: yup.string().required(passwordRequire).min(6, passwordLong),
    // .matches(REGEX_PASSWORD, "Password Error Format "),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => {
    setStateSnk(false);
  };

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const [reSendEmail, setReSendEmail] = useState(false);
  const [validEmail, setValidEmail] = useState(null);

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await login(values.email, values.password);
      const token = response?.data.token;
      const dataToken = response?.data.dataToken;
      setToken("ctknre", token);
      setToken("cdttkn", dataToken);
      goHome();
    } catch (err) {
      setReSendEmail(false);
      setValidEmail(null);
      setIsLoading(false);
      const errMsg = err.response.data.error;
      setErrorMessage(errMsg);
      if (errMsg === "UNVERIFIED_EMAIL") {
        setReSendEmail(true);
        setValidEmail(values.email);
      }
      setTimeout(() => {
        setErrorMessage(null);
      }, 4000);
    }
  };

  const sendEmailConfirm = async () => {
    try {
      setIsLoading(true);
      await reSendConfirmEmail(validEmail, "booking");
      snackBarDialog("EMAIL DE VERIFICACION ENVIADO");
      setReSendEmail(false);
      setIsLoading(false);
      setValidEmail(null);
    } catch (error) {
      snackBarDialog("ERROR, NO SE LOGRO ENVIAR EMAIL", "error");
      setReSendEmail(false);
      setIsLoading(false);
      setValidEmail(null);
      console.log(error);
    }
  };

  const facebookLogin = async (response) => {
    try {
      const facebookData = response.data;
      const rider = {
        name: facebookData.first_name,
        lastname: facebookData.last_name,
        email: facebookData.email ? facebookData.email : "",
        genre: 1,
        password: facebookData.id,
        repassword: facebookData.id,
        phone: "",
        address: "",
      };
      const fbResponse = await authRiderFacebookUseCase(rider);
      console.log(fbResponse);
      setImageProfile(facebookData.picture.data.url);
      setToken("ctknre", fbResponse.data.token);
      setToken("cdttkn", fbResponse.data.dataToken);
      goHome();
      console.log("accountSuccess");
    } catch (error) {
      console.log(error);
    }
  };

  const [tokenGoogle, setTokenGoogle] = useState(null);

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => setTokenGoogle(tokenResponse),
    onError: (error) => setTokenGoogle(null),
  });

  useEffect(() => {
    if (tokenGoogle != null) {
      const googleAuth = async () => {
        try {
          const response = await loginWithGoogleUseCase(
            tokenGoogle.access_token
          );
          const googleData = response.data;

          const rider = {
            name: googleData.given_name,
            lastname: googleData.family_name,
            email: googleData.email,
            genre: 1,
            password: googleData.id,
            repassword: googleData.id,
            phone: "",
            address: "",
          };

          const userData = await authRiderGoogleUseCase(rider);

          setImageProfile(googleData.picture);
          setToken("ctknre", userData.data.token);
          setToken("cdttkn", userData.data.dataToken);
          goHome();
          /*
            picture : "https://lh3.googleusercontent.com/a/ACg8ocLBowzk8YfLBWMKi6JwSiAN5f0fECtiLFIxjC1X897Xfzs=s96-c"
          */
        } catch (error) {
          console.log(error);
        }
      };
      googleAuth();
    }
  }, [tokenGoogle]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            style={{ margin: 3, width: 152, height: 152 }}
            alt="Logo"
          />

          <Typography component="h1" variant="h5">
            Taxi Mundo
          </Typography>

          <Box sx={{ mt: 1, maxWidth: 350, minWidth: 300 }}>
            <Notification description={t(errorMessage)} alertType="error" />
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        value={values.email}
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        value={values.password}
                        required
                        fullWidth
                        name="password"
                        label={t("password")}
                        type="password"
                        id="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 1 }}
                        disabled={isLoading}
                      >
                        {t("login")}
                      </Button>
                    </Grid>

                    {reSendEmail && (
                      <>
                        <Grid item xs={12} sm={12}>
                          <Typography variant="h7" fontStyle={"italic"}>
                            Enviar email de Verficacion a: {validEmail}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Button
                            fullWidth
                            color="secondary"
                            variant="contained"
                            sx={{ mb: 2 }}
                            onClick={(e) => {
                              e.preventDefault();
                              sendEmailConfirm();
                            }}
                            disabled={isLoading}
                          >
                            {t("ENVIAR")}
                          </Button>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={6} sm={6}>
                      <GoogleLoginButton
                        onClick={(e) => {
                          e.preventDefault();
                          googleLogin();
                        }}
                      >
                        <span>Google</span>
                      </GoogleLoginButton>
                    </Grid>
                    {/* 1040081927201199 */}
                    <Grid item xs={6} sm={6}>
                      <LoginSocialFacebook
                        appId="1040081927201199"
                        onResolve={facebookLogin}
                        onReject={(error) => {
                          console.log("error", error);
                        }}
                        className="facebookButton"
                      >
                        <FacebookLoginButton
                          onClick={(e) => e.preventDefault()}
                        >
                          <span>Facebook</span>
                        </FacebookLoginButton>
                      </LoginSocialFacebook>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Link to="/forgot" style={{ color: "black" }}>
                      {t("forgotPassword")}
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link to="/register" style={{ color: "black" }}>
                      {t("registerNow")}
                    </Link>
                  </Grid>

                  <SnackBar
                    stateSnk={stateSnk}
                    vertical={vertical}
                    horizontal={horizontal}
                    duration={5000}
                    handleClose={handleSnkClose}
                    severity={severity}
                    messageSnk={messageSnk}
                  />
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Copyright sx={{ mt: 4, mb: 2 }} />
      </Container>
    </ThemeProvider>
  );
}
