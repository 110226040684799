import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";

import logo from "../../assets/logo-main.png";
import Translation from "../translation/Translation";
import "./topMenu.css";
import {
  decodeClientToken,
  deleteToken,
  getClientToken,
  getTokenRefresh,
  verifyExpireTokenRefresh,
} from "../../helpers/token/refreshToken";
import {
  deleteImageProfile,
  getImageProfile,
} from "../../helpers/token/imageStore";

const settings = ["Email", "Profile", "History", "Logout"];

function TopMenu() {
  const [user, setUser] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [completeName, setCompleteName] = useState("");
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [imgProfile, setImgProfile] = useState("");

  useEffect(() => {
    const token = getTokenRefresh();
    if (token) {
      setUser(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      const verifyTkn = verifyExpireTokenRefresh();
      if (verifyTkn) {
        deleteToken("ctknre");
        deleteToken("cdttkn");
        deleteImageProfile();
        navigate("/");
      }

      const token = getClientToken();
      const { name } = decodeClientToken(token);
      const image = getImageProfile();
      if (image != undefined && image != null) setImgProfile(image);
      setCompleteName(`Hola, ${name}`);
    }
  }, [user]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (e) => {
    const option = e.target.innerHTML;
    if (option.includes("Profile") || option.includes("Perfil")) {
      navigate("/client/profile");
    }

    if (
      option.includes("Booking History") ||
      option.includes("Historial Pedidos")
    ) {
      navigate("/client/booking");
    }

    if (option.includes("Logout") || option.includes("Salir")) {
      deleteToken("ctknre");
      deleteToken("cdttkn");
      deleteImageProfile();
      setUser("");
      navigate("/");
    }

    setAnchorElUser(null);
  };

  const handleCloseUserMenuUser = (e) => {
    setAnchorElUser(null);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const renderBoxLogin = () => {
    return (
      <Box sx={{ flexGrow: 0 }}>
        <Button color="secondary" variant="contained" onClick={handleLogin}>
          {t("login")}
        </Button>
      </Box>
    );
  };

  const renderBoxUser = () => {
    return (
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            {imgProfile != "" ? (
              <Avatar src={imgProfile}></Avatar>
            ) : (
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            )}
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenuUser}
        >
          {settings.map((setting, index) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              {index === 0 && completeName ? (
                <Typography textAlign="center"> {completeName}</Typography>
              ) : (
                <Typography textAlign="center">{t(setting)}</Typography>
              )}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  return (
    <AppBar
      position="static"
      enableColorOnDark
      sx={{
        background: "#191975",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <img src={logo} alt="" width="100" height="50" />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <a
                    href="https://taximundo.com/about/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("about")}
                  </a>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <a
                    href="https://taximundo.com/support/index.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("contact")}
                  </a>
                </Typography>
              </MenuItem>
              {/* {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">  {t(page)} </Typography>
                </MenuItem>
              ))} */}
            </Menu>
          </Box>

          {/*
           */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <a
                href="https://taximundo.com/about/index.html"
                target="_blank"
                className="link"
              >
                {t("about")}
              </a>
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <a
                href="https://taximundo.com/support/index.html"
                target="_blank"
                className="link"
              >
                {t("contact")}
              </a>
            </Button>
          </Box>

          <Translation backgroundColor={"#4d4f4f"} />

          {user ? renderBoxUser() : renderBoxLogin()}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopMenu;
