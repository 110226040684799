import React from 'react'

const DeleteAccount = () => {
  return (
    <div>
      DELETE ACCOUNT
    </div>
  )
}

export default DeleteAccount
