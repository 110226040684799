import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import Login from "./views/home/login";
import Register from "./views/home/register";
import { ColorModeContext, useMode } from "./theme";
import ForgotPassword from "./views/home/forgot/Forgot";
import Home from "./views/home/Home";
import ProfileClient from "./views/users/clients/Profile";
import ResetPassword from "./views/home/forgot/ResetPassword";
import {
  ProtectedRoute,
  ProtectedClientRoute,
} from "./components/protected/auth/ProtectedRoute";
import Traveller from "./views/users/clients/Traveller";
import ConfirmEmail from "./views/home/confirm/ConfirmEmail";
import HistoryTab from "./views/users/clients/HistoryTab";
import DeleteAccount from "./views/users/clients/DeleteAccount";
import TransferPay from "./views/users/clients/confirm-pay/TransferPay";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route
              path="/reset-password/:id/:token"
              element={<ResetPassword />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
          </Route>

          <Route element={<ProtectedClientRoute />}>
            <Route path="/client">
              <Route index element={<Home />} />
              <Route path="profile" element={<ProfileClient />} />
              <Route path="booking" element={<HistoryTab />} />

              <Route path="traveller" element={<Traveller />} />

              <Route path="transfer/:currency" element={<TransferPay />} />
            </Route>
          </Route>
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
