import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBZgZ73vtJyhhtxsz_fzEFZqdYygjhuj8w",
  authDomain: "dbtaximundoapp.firebaseapp.com",
  databaseURL: "https://dbtaximundoapp-default-rtdb.firebaseio.com",
  projectId: "dbtaximundoapp",
  storageBucket: "dbtaximundoapp.appspot.com",
  messagingSenderId: "583153978106",
  appId: "1:583153978106:web:ccd27148fa654eb9281743",
  measurementId: "G-7PVH4QQD0V",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyABE1DQdCec-8DqNlYtMOkt39Bf-0Fv-wU",
//   authDomain: "taximundodev.firebaseapp.com",
//   databaseURL: "https://taximundodev-default-rtdb.firebaseio.com",
//   projectId: "taximundodev",
//   storageBucket: "taximundodev.appspot.com",
//   messagingSenderId: "960902306725",
//   appId: "1:960902306725:web:7ef6c34b4ef10ee8f7664c",
// };

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
